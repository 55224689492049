import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";
import {
  Grid,
  StylesProvider,
  Button,
  Hidden,
  Input,
  CircularProgress,
} from "@material-ui/core";
import image from "../../img/register.png";
import { ReactComponent as Logo } from "../../img/logo.svg";
import { Link } from "react-router-dom";
import {
  Firebase,
  register,
  signInWithFacebook,
  signInWithGoogle,
} from "../../Firebase/config";

const Register = () => {
  const classes = useStyles();
  const [name, setName] = useState();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [passconf, setPassconf] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (password !== passconf) {
      setLoading(false);
      setError(<FormattedMessage id="notSamePasswords"/>);
      return;
    }

    register(email, password, name);
    setLoading(false);
  };
  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={12} sm={8} md={6}>
          <form className={classes.form} onSubmit={handleSubmit}>
            <p>S'inscrire</p>
            <h1 className={classes.title}>
              <FormattedMessage id="enjoyActivities"/>
            </h1>
            <label className={classes.label} htmlFor="name">
              Nom
            </label>
            <Input
              classes={{
                root: classes.input,
              }}
              name="name"
              required
              disableUnderline
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
            <label className={classes.label} htmlFor="email">
              Email
            </label>
            <Input
              classes={{
                root: classes.input,
              }}
              name="email"
              type="email"
              required
              disableUnderline
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
            <label className={classes.label} htmlFor="password">
              Mot de passe
            </label>
            <Input
              classes={{
                root: classes.input,
              }}
              name="password"
              required
              disableUnderline
              type="password"
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />
            <label className={classes.label} htmlFor="passconf">
              <FormattedMessage id="confirmPassword"/>
            </label>
            <Input
              classes={{
                root: classes.input,
              }}
              name="passconf"
              required
              disableUnderline
              type="password"
              onChange={(event) => {
                setPassconf(event.target.value);
              }}
            />
            <p className={classes.error}>{error}</p>
            <div className={classes.flexbox}>
              {loading ? (
                <CircularProgress />
              ) : (
                <>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    classes={{ root: classes.button }}
                  >
                    S'inscrire
                  </Button>
                  <div>
                    <p style={{ marginBottom: 0 }}>
                      <FormattedMessage id="validateInfos"/>
                    </p>
                    <Link to="/login">Se connecter</Link>
                  </div>
                </>
              )}
            </div>
            <Button
              color="primary"
              variant="contained"
              classes={{ root: classes.buttonSocial }}
              onClick={() => {
                signInWithFacebook();
              }}
            >
              <FormattedMessage id="registerWithFB" />
            </Button>
            <Button
              color="primary"
              variant="contained"
              classes={{ root: classes.buttonSocial }}
              onClick={() => {
                signInWithGoogle();
              }}
            >
              <FormattedMessage id="registerWithGoogle" />
            </Button>
          </form>
        </Grid>
        <Hidden xsDown>
          <Grid item sm={4} md={6}>
            <div className={StylesProvider.aside}>
              <img src={image} alt="yoga" className={classes.image} />
              <Logo className={classes.logo} />
            </div>
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    padding: "20px 100px",
    justifyContent: "center",
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: 10,
    },
  },
  title: {
    marginTop: 40,
    marginBottom: 40,
  },
  aside: {
    position: "relative",
  },
  image: { objectFit: "cover", height: "100vh", width: "100%" },
  logo: {
    width: 100,
    position: "absolute",
    top: 0,
    right: 30,
  },
  icon: {
    color: "#3E7EF8",
  },
  label: {
    fontSize: 16,
    margin: "20px 10px",
  },
  input: {
    borderRadius: 4,
    backgroundColor: "#FFF",
    borderWidth: 1,
    borderColor: "#E8E8E8",
    borderStyle: "solid",
    "& input": {
      padding: 12,
    },
  },
  button: {
    borderRadius: 50,
    textTransform: "none",
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: 16,
    width: 180,
    marginRight: 10,
    marginTop: 30,
  },
  checkbox: {
    marginTop: 20,
    marginBottom: 20,
  },
  flexbox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  buttonSocial: {
    margin: 10,
  },
  error: {
    color: "red",
  },
}));
export default Register;
