import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { FirebaseContext } from "../Firebase/FirebaseContext";

const NoAuthRoute = ({ children, ...rest }) => {
  const { user } = useContext(FirebaseContext);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          <Redirect
            to={{
              pathname: "/home",
              state: { from: location },
            }}
          />
        ) : (
          children
        )
      }
    />
  );
};

export default NoAuthRoute;
