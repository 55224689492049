import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Login from "./Components/Auth/Login";
import Register from "./Components/Auth/Register";
import Landing from "./Components/Landing";
import Home from "./Components/Home/Home";
import Activity from "./Components/Activity/Activity";
import Purchase from "./Components/Activity/Purchase";
import CreateProAccount from "./Components/Pro/Forms/CreateProAccount";
import ProDashBoard from "./Components/Dashboard/ProDashboard";
import ClientDashBoard from "./Components/Dashboard/ClientDashboard";
import CreateActivity from "./Components/Pro/Forms/CreateActivity";
import NoAuthRoute from "./Wrappers/NoAuthRoute";
import PrivateRoute from "./Wrappers/PrivateRoute";
import ProRoute from "./Wrappers/ProRoute";
import Account from "./Components/Dashboard/Account";
import Meeting from "./Components/Activity/Meeting";
import CreateEvent from "./Components/Pro/Forms/CreateEvent";
import Activities from "./Components/Dashboard/Activities";
import Profile from "./Components/Profile";
import Pros from "./Components/Pro/Pros";
import Footer from "./Components/Footer";

const Routes = () => {
  return (
    <Router>
      <NoAuthRoute path="/login">
        <Login />
        <Footer />
      </NoAuthRoute>
      <NoAuthRoute path="/register">
        <Register />
        <Footer />
      </NoAuthRoute>
      <Route exact path="/">
        <Landing />
        <Footer />
      </Route>
      <Route path="/home">
        <Home />
        <Footer />
      </Route>

      <Route exact path="/pros">
        <Pros />
        <Footer />
      </Route>
      <PrivateRoute exact path="/activity/:id/purchase">
        <Purchase />
        <Footer />
      </PrivateRoute>
      <Route exact path="/activity/:id">
        <Activity />
        <Footer />
      </Route>
      <PrivateRoute exact path="/pro/create">
        <CreateProAccount />
        <Footer />
      </PrivateRoute>
      <ProRoute exact path="/pro/dashboard">
        <ProDashBoard />
        <Footer />
      </ProRoute>

      <PrivateRoute path="/dashboard">
        <ClientDashBoard />
        <Footer />
      </PrivateRoute>
      <ProRoute exact path="/pro/createactivity">
        <CreateActivity />
        <Footer />
      </ProRoute>
      <ProRoute exact path="/pro/activities">
        <Activities />
        <Footer />
      </ProRoute>
      <ProRoute exact path="/pro/event/:activity">
        <CreateEvent />
        <Footer />
      </ProRoute>
      <PrivateRoute exact path="/p/:proName">
        <Profile />
        <Footer />
      </PrivateRoute>
      <PrivateRoute exact path="/account">
        <Account />
        <Footer />
      </PrivateRoute>
      <PrivateRoute exact path="/meeting/:password/:id">
        <Meeting />
        <Footer />
      </PrivateRoute>
    </Router>
  );
};

export default Routes;
