import axios from "axios";

export const URL = "https://api-codjy-live.herokuapp.com/";
export const axiosInstance = axios.create({
  baseURL: URL,
  timeout: 8000,
  responseType: "json",
  headers: { "X-Custom-Header": "foobar" },
});

export default function RequestAPI(method = "GET", url = "/", data = []) {
  return axios({
    method: method,
    url: URL + url,
    data: data,
    responseType: "json",
    headers: { "Access-Control-Allow-Origin": "*" },
  });
}

export function RequestAPIAuth(
  method = "GET",
  url = "/",
  data = [],
  authToken = ""
) {
  return axios({
    method: method,
    url: URL + url,
    data: data,
    responseType: "json",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: authToken,
    },
  });
}

export const CHECK_USERNAME = `check-username/`;
