import React, { useContext, useState } from "react";
import logoGray from "../img/logo_pink.png";
import logo from "../img/logo_right.svg";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, Link, useLocation } from "react-router-dom";
import { Button, Hidden, Menu, MenuItem, Drawer } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";
import { FirebaseContext } from "../Firebase/FirebaseContext";
import Searchbar from "./Inputs/Searchbar";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { FormattedMessage } from "react-intl";

const Header = ({
  hideLogo = false,
  color = "#FFF",
  searchValue = "",
  handleSearch = () => {},
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const { user, logout } = useContext(FirebaseContext);
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <div className={classes.headerFlex}>
        {hideLogo ? (
          <div></div>
        ) : (
          <img
            style={{ width: 250 }}
            src={color === "#FFF" ? logo : logoGray}
            alt="CodjyLive"
            className={classes.logo}
            onClick={() => {
              history.push("/home");
            }}
          />
        )}
        <Hidden smDown>
          {location.pathname === "/home" && (
            <Searchbar value={searchValue} onChange={handleSearch} />
          )}

          {location.pathname === "/pros" && (
            <Searchbar value={searchValue} onChange={handleSearch} />
          )}
        </Hidden>

        <Hidden smDown>
          <div className={classes.menu}>
            <Link
              to="/pros"
              className={classes.links}
              style={{
                color: "#261170",
                display: "flex",
                alignItems: "center",
              }}
            >
              <AccountCircleIcon style={{ fontSize: 40 }} />
            </Link>
            {user ? (
              <>
                {!user.isPro && (
                  <Button
                    variant="contained"
                    className={classes.button}
                    onClick={() => {
                      history.push("/pro/create");
                    }}
                  >
                    Créer un compte pro
                  </Button>
                )}
                <Button
                  className={classes.dropdown}
                  onClick={handleOpen}
                  endIcon={<ExpandMoreIcon />}
                >
                  {user.name ||
                    user.email.substring(0, user.email.indexOf("@"))}
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      history.push("/dashboard");
                    }}
                  >
                    Dashboard
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      history.push("/account");
                    }}
                  >
                    <FormattedMessage id="myAccount"/>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      logout();
                    }}
                  >
                    <FormattedMessage id="logout"/>
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <Button
                variant="contained"
                className={classes.button}
                onClick={() => {
                  history.push("/login");
                }}
              >
                Se connecter
              </Button>
            )}
          </div>
        </Hidden>
        <Hidden mdUp>
          <MenuIcon
            color="secondary"
            onClick={() => {
              setOpen(true);
            }}
            className={classes.menuIcon}
          ></MenuIcon>
          <Drawer
            anchor="right"
            variant="temporary"
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            classes={{
              root: { minWidth: 200 },
            }}
          >
            <div className={classes.drawer}>
              <Link
                to="/pros"
                className={classes.links}
                style={{
                  color: "#261170",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <AccountCircleIcon style={{ fontSize: 40 }} />{" "}
                <p style={{ marginLeft: 3 }}>Rechercher un pro</p>
              </Link>
              <Link to="#" className={classes.drawerLinks}>
                Contact
              </Link>
              <Link to="#" className={classes.drawerLinks}>
                <FormattedMessage id="generalConditions"/>
              </Link>
              <Link to="#" className={classes.drawerLinks}>
                FAQ
              </Link>
              {user ? (
                <>
                  {!user.isPro && (
                    <Link to="/pro/create" className={classes.drawerLinks}>
                      Créer un compte pro
                    </Link>
                  )}
                  <Link to="/dashboard" className={classes.drawerLinks}>
                    Dashboard
                  </Link>

                  <Link to="/account" className={classes.drawerLinks}>
                    <FormattedMessage id="myAccount"/>
                  </Link>
                  <p
                    className={classes.drawerLinks}
                    onClick={() => {
                      logout();
                    }}
                  >
                    <FormattedMessage id="logout"/>
                  </p>
                </>
              ) : (
                <Link to="/login" className={classes.drawerLinks}>
                  Se connecter
                </Link>
              )}
            </div>
          </Drawer>
        </Hidden>
      </div>
      <Hidden mdUp>
        {location.pathname === "/home" && (
          <Searchbar
            value={searchValue}
            onChange={handleSearch}
            inputStyles={{ width: "90%", margin: "5%" }}
          />
        )}
      </Hidden>
    </>
  );
};
const useStyles = makeStyles({
  headerFlex: {
    width: "100%",
    paddingTop: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo: {
    marginLeft: 10,
    cursor: "pointer",
  },
  menu: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    "& *": {
      marginRight: 10,
    },
  },
  drawer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    "& *": {
      marginRight: 10,
    },
    padding: 20,
  },
  links: {
    color: "#FFF",
    textDecoration: "unset",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  drawerLinks: {
    color: "#000",
    textDecoration: "unset",
    "&:hover": {
      textDecoration: "underline",
    },
    padding: 10,
  },
  button: {
    backgroundColor: "#FFF",
    color: "#261170",
    borderRadius: 50,
    textTransform: "unset",
    textAlign: "center",
  },
  dropdown: {
    textTransform: "unset",
    color: "#FF2260",
  },
  menuIcon: {
    padding: 10,
    fontSize: 30,
  },
});
export default Header;
