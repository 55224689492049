import React from "react";
import { makeStyles, Input } from "@material-ui/core";

const ProInput = ({
  label = "",
  labelStyles = {},
  placeholder = "",
  placeholderStyles = {},
  inputStyles = {},
  value,
  onChange = () => {},
  onBlur = () => {},
  disableUnderline = true,
  ...props
}) => {
  const classes = useStyles();
  return (
    <>
      <label className={classes.label} style={labelStyles}>
        {label}
      </label>
      <Input
        classes={{
          root: Object.assign(classes.input, inputStyles),
          multiline: classes.multiline,
          inputMultiline: classes.multilineInput,
        }}
        style={inputStyles}
        disableUnderline={disableUnderline}
        value={value}
        {...props}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
      />
    </>
  );
};
const useStyles = makeStyles((theme) => ({
  input: {
    minWidth: "50%",
    backgroundColor: "#FFF",
    borderWidth: "1px",
    borderColor: "#979797",
    borderStyle: "solid",
    borderRadius: 15,
    padding: "5px 10px",
    margin: "10px 0",
    boxShadow: "0 2px 5px rgba(0,0,0,0.5)",
  },
  multiline: {
    minHeight: 250,
    backgroundColor: "#FFF",
    borderWidth: "1px",
    borderColor: "#979797",
    borderStyle: "solid",
    borderRadius: 15,
    padding: "5px 10px",
    margin: "10px 0",
    boxShadow: "0 2px 5px rgba(0,0,0,0.5)",
  },
  multilineInput: {
    minHeight: 240,
  },
  label : {
      color : "#979797",
      marginTop : 5,
  },
  placeholder : {
    color : "#979797",
  }
}));
export default ProInput;
