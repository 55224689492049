import React, { useState, useEffect, useContext } from "react";
import { makeStyles, Button, Paper, Avatar } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import Header from "../../Header";
import ProInput from "../../Inputs/ProInput";
import ProCheckbox from "../../Inputs/ProCheckbox";
import { RequestAPIAuth } from "../../../Links";
import { FirebaseContext } from "../../../Firebase/FirebaseContext";
import { useSnackbar } from "notistack";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { FormattedMessage } from "react-intl";
import "moment/locale/fr";
const CreateEvent = () => {
  const classes = useStyles();
  const history = useHistory();
  const [image, setImage] = useState({});
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [infos, setInfos] = useState({});
  const { firebaseUser, user } = useContext(FirebaseContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { activity } = useParams();
 


  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    enqueueSnackbar(<FormattedMessage id="loading"/>, {
      variant: "info",
      key: "loading",
    });
    const token = await firebaseUser.getIdToken();
    RequestAPIAuth(
      "POST",
      "event",
      {
        ...infos,
        activity: activity,
      },
      token
    )
      .then((res) => {
        closeSnackbar("loading");
        console.log(res.data);
        enqueueSnackbar("Evènement créée !", {
          variant: "success",
        });
        history.push("/pro/activities");
      })
      .catch((err) => {
        setLoading(false);
        closeSnackbar("loading");
        enqueueSnackbar(<FormattedMessage id="activityCreationError"/>, {
          variant: "error",
        });
      });
  };
  return (
    <div className={classes.wrapper}>
      <Header />
      <form className={classes.container} onSubmit={onSubmit}>
        <h1 className={classes.title}><FormattedMessage id="createEvent"/></h1>
        <Paper className={classes.form}>
          <div>
            <p className={classes.label}><FormattedMessage id="dateHourBegining"/></p>
            <Datetime
              locale="fr"
              inputProps={{ className: classes.input, required: true }}
              onChange={(date) => {
                setInfos({ ...infos, startDate: date });
              }}
            />
          </div>

          <div>
            <p className={classes.label}><FormattedMessage id="dateHourEnding"/></p>
            <Datetime
              locale="fr"
              inputProps={{ className: classes.input }}
              onChange={(date) => {
                setInfos({ ...infos, endDate: date });
              }}
            />
          </div>

          <ProInput
            label="Combien de places sont disponibles pour votre activité ?"
            type="number"
            min="0"
            onChange={(e) => {
              setInfos({ ...infos, totalSeats: e.target.value });
            }}
            required
          />
          <div className={classes.flexCenter}>
            <ProCheckbox
              required
              label="J’accepte la charte éthique 
                et professionnelle Codjy"
            />

            <Button
              className={classes.button}
              color="secondary"
              variant="contained"
              type="submit"
            >
              <FormattedMessage id="validatePost"/>
            </Button>
          </div>
        </Paper>
      </form>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: "100vh",
    backgroundColor: "#261170",
    padding: 10,
  },
  headerFlex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  container: {
    width: "80%",
    margin: "50px auto",
    color: "#FFF",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "flex-start",
    borderRadius: 30,
    padding: 30,
    "& p": {
      color: "#8A94A6",
    },
  },
  flexCenter: {
    alignSelf: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    margin: 20,
  },
  onlineContainer: {
    display: "flex",
  },
  onlineForm: {
    marginTop: -5,
    marginLeft: 15,
  },
  picture: {
    alignSelf: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    margin: 20,
    border: "solid",
    borderWidth: 1,
    borderRadius: 16,
    borderColor: "#979797",
    maxWidth: 400,
    height: 200,
    width: "50%",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  imagePreview: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  avatar: {
    width: 80,
    height: 80,
  },
  doubleInput: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    minWidth: "50%",
  },
  subtitle: {
    fontWeight: "bold",
    color: "#8A94A6",
    fontSize: 20,
  },
  links: {
    color: "#FFF",
    textDecoration: "unset",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  button: {
    borderRadius: 50,
    textTransform: "none",
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: 16,
    marginTop: 10,
  },
  input: {
    minWidth: "48%",
    backgroundColor: "#FFF",
    borderWidth: "1px",
    borderColor: "#979797",
    borderStyle: "solid",
    borderRadius: 15,
    padding: "5px 10px",
    margin: "10px 0",
    boxShadow: "0 2px 5px rgba(0,0,0,0.5)",
    height: "30px",
  },
  label: {
    color: "#979797",
    marginTop: 5,
    marginBottom: 0,
  },
}));

export default CreateEvent;
