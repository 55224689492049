import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { a } from "react-router-dom";
import YouTubeIcon from "@material-ui/icons/YouTube";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import { FormattedMessage } from "react-intl";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { useLocale } from "../Wrappers/LocaleContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    padding: "5px 0",

    [theme.breakpoints.up("850")]: {
      height: 340,
    },
    [theme.breakpoints.down("850")]: {
      height: "auto",
    },
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "85%",
    height: "50%",
    flexWrap: "wrap",

    [theme.breakpoints.up("850")]: {
      margin: "20px 0",
    },
    [theme.breakpoints.down("850")]: {
      margin: 0,
    },
  },
  col: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column",
    height: "100%",

    [theme.breakpoints.up("850")]: {
      minWidth: "30%",
    },
    [theme.breakpoints.down("850")]: {
      minWidth: "80%",
    },
  },
  greyStroke: {
    height: 1,
    width: "85%",
    backgroundColor: "#bababa",
    margin: 30,
  },
  colTitle: {
    fontWeight: 500,
    color: "#949494",

    [theme.breakpoints.up("850")]: {
      marginTop: 0,
    },
    [theme.breakpoints.down("850")]: {
      marginTop: 25,
    },
  },
  links: {
    textDecoration: "none",
    fontSize: 16,
    color: "#949494",
    "&:hover": {
      color: "#f50057",
    },
    margin: "5px 0",
  },
  mediaGroup: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  mediaIcon: {
    [theme.breakpoints.up("850")]: {
      marginRight: 3,
    },
    [theme.breakpoints.down("850")]: {
      marginRight: 6,
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { setLocale } = useLocale();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div className={classes.greyStroke}></div>
      <div className={classes.root}>
        <div className={classes.row}>
          <div className={classes.col}>
            <p className={classes.colTitle}>
              <FormattedMessage id="codjyActivities" />
            </p>
            <a href="#" className={classes.links}>
              <FormattedMessage id="participate" />
            </a>
            <a href="#" className={classes.links}>
              <FormattedMessage id="online" />
            </a>
            <a href="https://www.codjy.info/faq-1" className={classes.links}>
              COVID-19
            </a>
            <a href="https://www.codjy.info/faq-1" className={classes.links}>
              <FormattedMessage id="faq" />
            </a>
          </div>
          <div className={classes.col}>
            <p className={classes.colTitle}>
              <FormattedMessage id="genInfo" />
            </p>
            <a
              href="https://www.codjy.info/conditions-generales"
              className={classes.links}
            >
              <FormattedMessage id="cgu" />
            </a>
            <a
              href="https://www.codjy.info/conditions-generales"
              className={classes.links}
            >
              <FormattedMessage id="cgv" />
            </a>
            <a
              href="https://www.codjy.info/conditions-generales"
              className={classes.links}
            >
              <FormattedMessage id="cancelPolicy" />
            </a>
            <a
              href="https://www.codjy.info/conditions-generales"
              className={classes.links}
            >
              <FormattedMessage id="confidentialityPolicy" />
            </a>
            <a
              href="https://www.codjy.info/conditions-generales"
              className={classes.links}
            >
              <FormattedMessage id="cookies" />
            </a>
          </div>
          <div className={classes.col}>
            <p className={classes.colTitle}>
              <FormattedMessage id="whoAreWe" />
            </p>
            <a href="https://www.codjy.info/blog-1" className={classes.links}>
              <FormattedMessage id="pressReview" />
            </a>
            <a href="https://www.codjy.info/blog-1" className={classes.links}>
              <FormattedMessage id="story" />
            </a>
            <a href="https://www.codjy.info/blog-1" className={classes.links}>
              <FormattedMessage id="blog" />
            </a>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col}>
            <p className={classes.colTitle}>
              <FormattedMessage id="help" />
            </p>
            <div className={classes.mediaGroup}>
              <a
                href="https://www.instagram.com/codjy_/"
                className={classes.links}
              >
                <InstagramIcon className={classes.mediaIcon} />
              </a>
              <a href="https://twitter.com/CODJY_" className={classes.links}>
                <TwitterIcon className={classes.mediaIcon} />
              </a>
              <a
                href="https://www.facebook.com/Codjy-104944435169779"
                className={classes.links}
              >
                <FacebookIcon className={classes.mediaIcon} />
              </a>
              <a
                href="https://www.youtube.com/channel/UCHNo4_HXU990gv3OFzAMMhA"
                className={classes.links}
              >
                <YouTubeIcon className={classes.mediaIcon} />
              </a>
            </div>
            <Button onClick={(e) => setAnchorEl(e.currentTarget)}>
              <FormattedMessage id="languages" />
            </Button>
            <Menu
              open={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorEl={anchorEl}
            >
              <MenuItem
                onClick={() => {
                  setLocale("fr");
                  setAnchorEl(null);
                }}
              >
                <FormattedMessage id="fr" />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setLocale("nl");
                  setAnchorEl(null);
                }}
              >
                <FormattedMessage id="nl" />
              </MenuItem>
            </Menu>
          </div>
          <div className={classes.col}>
            <a href="#" className={classes.links}>
              <p style={{ fontWeight: 500, marginTop: 0 }}>
                <FormattedMessage id="downloadApp" />
              </p>
            </a>
          </div>
          <div className={classes.col}>
            <p className={classes.colTitle}>Devenir PRO</p>
            <a href="https://www.codjy.info" className={classes.links}>
              <FormattedMessage id="becomePro" />
            </a>
            <a href="https://www.codjy.info" className={classes.links}>
              <FormattedMessage id="coach" />
            </a>
            <a href="https://www.codjy.info" className={classes.links}>
              <FormattedMessage id="proFaq" />
            </a>
          </div>
        </div>
      </div>
      <div className={classes.greyStroke}></div>
    </div>
  );
};

export default Footer;
