import React, { useContext, useState } from "react";
import {
  makeStyles,
  Button,
  CircularProgress,
  Avatar,
  Hidden,
  Drawer,
} from "@material-ui/core";
import Sidebar from "./Sidebar";
import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router-dom";
import { FirebaseContext } from "../../Firebase/FirebaseContext";
import ProInput from "../Inputs/ProInput";
import { useSnackbar } from "notistack";
import { RequestAPIAuth } from "../../Links";
import { getImageUrl, uploadFile } from "../../Firebase/config";
import MenuIcon from "@material-ui/icons/Menu";
import { FormattedMessage } from "react-intl";

const Account = () => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { user, firebaseUser, setUser } = useContext(FirebaseContext);
  const [image, setImage] = useState();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modification, setModification] = useState(false);
  const [infos, setInfos] = useState({
    name: user.name,
    website: user.website,
    picture: user.picture,
    description: user.description,
  });

  const cancel = () => {
    setInfos({
      name: user.name,
      website: user.website,
      picture: user.picture,
      description: user.description,
    });
    setModification(false);
  };
  const handleImage = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setImage({ file: file, imagePreviewUrl: reader.result });
    };
    console.log(file);
    reader.readAsDataURL(file);
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    enqueueSnackbar(<FormattedMessage id="loadingInProgress" />, {
      variant: "info",
      persist: true,
      key: "loading",
    });

    if (image) {
      const filename = `pros/${user._id}_${new Date().getTime()}`;
      try {
        const snapshot = await uploadFile(filename, image.file);
        enqueueSnackbar(<FormattedMessage id="photoSent" />, {
          variant: "info",
        });
        console.log(snapshot);
        setInfos({ ...infos, picture: filename });
      } catch (error) {
        closeSnackbar("loading");
        setLoading(false);

        enqueueSnackbar(<FormattedMessage id="errorSendingImg" />, {
          variant: "error",
        });
        return;
      }
    }

    const token = await firebaseUser.getIdToken();

    RequestAPIAuth("PUT", "user/" + firebaseUser.uid, { ...infos }, token)
      .then((res) => {
        closeSnackbar("loading");
        setUser(res.data.user);
        enqueueSnackbar(<FormattedMessage id="infosUpdates" />, {
          variant: "success",
        });
        setLoading(false);
      })
      .catch((err) => {
        closeSnackbar("loading");
        setLoading(false);
        console.log(err);
        enqueueSnackbar(<FormattedMessage id="errorUpdateAccount" />, {
          variant: "error",
        });
      });
  };
  return (
    <div className={classes.wrapper}>
      {/* Desktop disposition */}
      <Hidden smDown>
        <Sidebar />
        <div className={classes.container}>
          <div className={classes.subheader}>
            <h1 className={classes.greetings}>
              <FormattedMessage id="myAccount" />
            </h1>
            {user.isPro ? (
              <Button
                variant="contained"
                color="secondary"
                className={classes.addButton}
                onClick={() => {
                  history.push("/pro/dashboard");
                }}
              >
                <FormattedMessage id="proSpace" />
              </Button>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                className={classes.addButton}
                endIcon={<AddIcon />}
                onClick={() => {
                  history.push("/pro/create");
                }}
              >
                Activer compte PRO
              </Button>
            )}
          </div>
          <h2 className={classes.subtitle}>
            <FormattedMessage id="hereItIsInfoRecap" />
          </h2>
          <form className={classes.form} onSubmit={onSubmit}>
            <input
              accept=".jpg, .jpeg, .png"
              style={{ display: "none" }}
              id="contained-button-file"
              type="file"
              onChange={handleImage}
            />

            <label
              htmlFor="contained-button-file"
              className={classes.flexCenter}
            >
              <Avatar
                src={getImageUrl(user.picture) || image.imagePreviewUrl}
                className={classes.avatar}
              />
            </label>
            <ProInput
              label="Nom :"
              onChange={(e) => {
                setInfos({ ...infos, name: e.target.value });
              }}
              value={infos.name}
              required={user.isPro}
              disabled={!modification}
            />

            {user.isPro && (
              <>
                <ProInput
                  label="Site web :"
                  onChange={(e) => {
                    setInfos({ ...infos, website: e.target.value });
                  }}
                  value={infos.website}
                  disabled={!modification}
                  required
                />
                <ProInput
                  label="Description :"
                  fullWidth
                  multiline
                  value={infos.description}
                  onChange={(e) =>
                    setInfos({ ...infos, description: e.target.value })
                  }
                  disabled={!modification}
                  required
                />
              </>
            )}
            {modification ? (
              !loading ? (
                <>
                  <Button
                    className={classes.button}
                    color="secondary"
                    variant="contained"
                    type="submit"
                  >
                    <FormattedMessage id="validateInfos" />
                  </Button>
                  <Button
                    className={classes.button}
                    variant="contained"
                    onClick={cancel}
                  >
                    <FormattedMessage id="cancel" />
                  </Button>
                </>
              ) : (
                <CircularProgress color="secondary" />
              )
            ) : (
              <Button
                className={classes.button}
                color="secondary"
                variant="contained"
                onClick={() => {
                  setModification(true);
                }}
              >
                <FormattedMessage id="modifyInfos" />
              </Button>
            )}
            {}
          </form>
        </div>
      </Hidden>

      {/* Mobile disposition */}
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          classes={{
            root: { minWidth: 200 },
          }}
        >
          <Sidebar />
        </Drawer>

        <div className={classes.menuSideBar}>
          <MenuIcon
            className={classes.menuIcon}
            onClick={() => {
              setOpen(true);
            }}
          />
        </div>

        <div className={classes.container}>
          <div className={classes.subheader}>
            <h1 className={classes.greetings}>
              <FormattedMessage id="myAccount" />
            </h1>
            {user.isPro ? (
              <Button
                variant="contained"
                color="secondary"
                className={classes.addButton}
                onClick={() => {
                  history.push("/pro/dashboard");
                }}
              >
                <FormattedMessage id="proSpace" />
              </Button>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                className={classes.addButton}
                endIcon={<AddIcon />}
                onClick={() => {
                  history.push("/pro/create");
                }}
              >
                Activer compte PRO
              </Button>
            )}
          </div>
          <h2 className={classes.subtitle}>
            <FormattedMessage id="hereItIsInfoRecap" />
          </h2>
          <form className={classes.form} onSubmit={onSubmit}>
            <input
              accept=".jpg, .jpeg, .png"
              style={{ display: "none" }}
              id="contained-button-file"
              type="file"
              onChange={handleImage}
            />

            <label
              htmlFor="contained-button-file"
              className={classes.flexCenter}
            >
              <Avatar
                src={getImageUrl(user.picture) || image.imagePreviewUrl}
                className={classes.avatar}
              />
            </label>
            <ProInput
              label="Nom :"
              onChange={(e) => {
                setInfos({ ...infos, name: e.target.value });
              }}
              value={infos.name}
              required={user.isPro}
              disabled={!modification}
            />

            {user.isPro && (
              <>
                <ProInput
                  label="Site web :"
                  onChange={(e) => {
                    setInfos({ ...infos, website: e.target.value });
                  }}
                  value={infos.website}
                  disabled={!modification}
                  required
                />
                <ProInput
                  label="Description :"
                  fullWidth
                  multiline
                  value={infos.description}
                  onChange={(e) =>
                    setInfos({ ...infos, description: e.target.value })
                  }
                  disabled={!modification}
                  required
                />
              </>
            )}
            {modification ? (
              !loading ? (
                <>
                  <Button
                    className={classes.button}
                    color="secondary"
                    variant="contained"
                    type="submit"
                  >
                    <FormattedMessage id="validateInfos" />
                  </Button>
                  <Button
                    className={classes.button}
                    variant="contained"
                    onClick={cancel}
                  >
                    <FormattedMessage id="cancel" />
                  </Button>
                </>
              ) : (
                <CircularProgress color="secondary" />
              )
            ) : (
              <Button
                className={classes.button}
                color="secondary"
                variant="contained"
                onClick={() => {
                  setModification(true);
                }}
              >
                <FormattedMessage id="modifyInfos" />
              </Button>
            )}
            {}
          </form>
        </div>
      </Hidden>
    </div>
  );
};
const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
  },
  container: {
    width: "70%",
    padding: "40px 20px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "flex-start",
    borderRadius: 30,
    padding: 30,
    "& p": {
      color: "#8A94A6",
    },
  },
  subheader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  greetings: {
    color: "#FF2260",
    fontWeight: "normal",
  },
  addButton: {
    textTransform: "none",
    borderRadius: 25,
  },
  subtitle: {
    color: "#8A94A6",
    fontSize: 16,
    fontWeight: "normal",
    marginBottom: 0,
  },
  tableCell: {
    color: "#9B9B9B",
    border: "none",
  },
  tableRow: {
    border: "1px solid #9B9B9B",
    borderRadius: 5,
  },
  tableLink: {
    border: "none",
    display: "flex",
    alignItems: "center",
    color: "#9B9B9B",
  },
  avatar: {
    width: 150,
    height: 150,
    margin: "0 10px 0 20px",
    cursor: "pointer",
  },
  menuSideBar: {
    width: 75,
    height: 80,
    position: "sticky",
    top: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    color: "#f50057",
    paddingTop: 65,
  },
  menuIcon: {
    height: "1.65em",
    width: "1.65em",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
export default Account;
