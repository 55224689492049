import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Hidden, Drawer, Button, Avatar } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { useSnackbar } from "notistack";
import HeaderWithoutSearch from "../HeaderWithoutSearch";
import RequestAPI from "../../Links";
import { getImageUrl } from "../../Firebase/config";
import { Link } from "react-router-dom";
import Searchbar from "../Inputs/Searchbar";
import { FormattedMessage } from "react-intl";

const Pros = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [pros, setPros] = useState([]);
  const [query, setQuery] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (query.length > 0) {
      const data = encodeURIComponent(query);
      RequestAPI("GET", "pros?search=" + data).then((res) => {
        setPros(res.data.pros);
      });
    }
  }, [query]);

  useEffect(() => {
      const data = encodeURIComponent(query);
      RequestAPI("GET", "pros").then((res) => {
        setPros(res.data.pros);
      });
  }, []);

  return (
    <div className={classes.container}>
      <HeaderWithoutSearch
        color="#9B9B9B"
        handleSearch={(e) => setQuery(e.target.value)}
        searchValue={query}
      />
      <Grid container>

        <div className={classes.searchContainer}>
          <p className={classes.titleBig}>Rechercher un professionnel</p>
          <Searchbar
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            className={classes.searchBar}
          />
        </div>

        <Grid xs={12} md={12}>
          <div className={classes.activities}>
            {pros.map((pro) => {
              return (
                <div>
                  <Link to={"/p/" + pro.username} className={classes.infos}>
                    <Avatar
                      className={classes.avatar}
                      src={getImageUrl(pro.picture)}
                      className={classes.proPicture}
                      style={{
                        width: 200,
                        height: 200,
                      }}
                    />
                    {/* <img
                      src={
                        pro.picture
                          ? getImageUrl(pro.picture)
                          : "https://i.ibb.co/mD1cx1y/image-not-found-min.jpg"
                      }
                      alt="pro-picture"
                      className={classes.proPicture}
                      style={{
                        width: 200,
                        height: 200,
                      }}
                    /> */}
                    <div className={classes.line}>
                      <h3 className={classes.textValue}>@{pro.username}</h3>
                    </div>
                    <div className={classes.greyStroke}></div>
                    <div className={classes.line}>
                      <Button
                        color="secondary"
                        variant="contained"
                        classes={{
                          root: classes.button,
                        }}
                      >
                        <FormattedMessage id="learnMore"/>
                      </Button>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
    backgroundColor: "#FFF",
  },
  activities: {
    display: "flex",
    flexWrap: "wrap",
    paddingTop: 25,
    justifyContent: "stretch",
    alignItems: "stretch",
    width: "100%",
    marginBottom: 75,
  },
  headerFlex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
  },
  menuIcon: {
    padding: 10,
    fontSize: 30,
  },
  proPicture: {
    borderRadius: "50%",
    width: 250,
    height: 250,
    objectFit: "cover",
    boxShadow: "0px 5px 12px -2px rgba(0,0,0,0.23)",
    margin: "auto",
    marginBottom: 25,
  },
  infos: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    padding: 50,
    maxWidth: "auto",
    boxShadow: "0px 5px 12px -2px rgba(0,0,0,0.23)",
    borderRadius: 23,
    margin: 25,
    textDecoration: "none",
  },
  line: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "no-wrap",
    width: "100%",
    justifyContent: "center",
  },
  textValue: {
    fontSize: 17,
    margin: 10,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    color: "#ffffff",
    backgroundColor: "#261170",
    borderRadius: 50,
    padding: "12px 8px",
    fontWeight: 400,
  },
  titleBig: {
    fontSize: 20,
    width: "85%",
    marginBottom: 25,
    marginTop: 0,
    textAlign: "center",
    color: "#ffffff"
  },
  button: {
    width: "150%",
    textTransform: "none",
    borderRadius: 20,
    marginTop: 10,
  },
  greyStroke: {
    width: "100%",
    height: 2,
    backgroundColor: "rgba(227, 227, 227, .5)",
    margin: "15px 0",
    borderRadius: "50%",
  },
  searchContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    flexDirection: "column",
    backgroundColor: "#261170",
    padding: "30px 0",
    marginTop: 30,
  },
  searchBar: {
    width: "85%", 
    margin: "5px auto",
  },
}));
export default Pros;
