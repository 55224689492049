import React, { useState, useEffect, useContext } from "react";
import {
  makeStyles,
  Button,
  Paper,
  Avatar,
  CircularProgress,
} from "@material-ui/core";
import { Redirect, useHistory } from "react-router-dom";
import Header from "../../Header";
import ProInput from "../../Inputs/ProInput";
import ProCheckbox from "../../Inputs/ProCheckbox";
import RequestAPI, { CHECK_USERNAME, RequestAPIAuth } from "../../../Links";
import { FirebaseContext } from "../../../Firebase/FirebaseContext";
import { useSnackbar } from "notistack";
import { uploadFile } from "../../../Firebase/config";
import { FormattedMessage } from "react-intl";

const CreateProAccount = () => {
  const classes = useStyles();
  const history = useHistory();
  const [image, setImage] = useState({});
  const [loading, setLoading] = useState(false);
  const [avaliable, setAvaliable] = useState();
  const [usernameError, setUsernameError] = useState();
  const [infos, setInfos] = useState({});
  const { firebaseUser, user } = useContext(FirebaseContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleImage = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setImage({ file: file, imagePreviewUrl: reader.result });
    };
    console.log(file);
    reader.readAsDataURL(file);
  };

  const handlePseudoChange = (e) => {
    const value = e.target.value;
    console.log(value);
    setInfos({ ...infos, username: value });

    var letters = /^[0-9a-zA-Z]+$/;
    if (value.match(letters)) {
      if (value.length >= 4) {
        RequestAPI("GET", CHECK_USERNAME + value)
          .then((res) => {
            if (!res.data.taken) {
              setAvaliable(true);
              setUsernameError("");
            } else {
              setAvaliable(false);
              setUsernameError(<FormattedMessage id="pseudoUnavailable"/>);
            }

            console.log(res);
          })
          .catch(() => {});
      } else {
        setAvaliable(false);
        setUsernameError(<FormattedMessage id="pseudoMustBe4Max"/>);
      }
    } else {
      setAvaliable(false);
      setUsernameError(<FormattedMessage id="enterAlphanumericOnly"/>);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    enqueueSnackbar(<FormattedMessage id="loadingInProgress"/>, {
      variant: "info",
      persist: true,
      key: "loading",
    });
    if (!avaliable) {
      closeSnackbar("loading");
      setLoading(false);
      enqueueSnackbar(<FormattedMessage id="badPseudo"/>, { variant: "warning" });
      return;
    }
    const filename = `pros/${user._id}_${new Date().getTime()}`;
    try {
      const snapshot = await uploadFile(filename, image.file);
      enqueueSnackbar(<FormattedMessage id="photoSent"/>, {
        variant: "info",
      });
      console.log(snapshot);
      console.log(filename);

      const token = await firebaseUser.getIdToken();

      RequestAPIAuth("POST", "pro", { ...infos, picture: filename }, token)
        .then((res) => {
          closeSnackbar("loading");
          enqueueSnackbar("Redirection en cours", { variant: "success" });
          setLoading(false);
          window.location.replace(res.data.stripeUrl);
        })
        .catch((err) => {
          closeSnackbar("loading");
          setLoading(false);

          enqueueSnackbar(<FormattedMessage id="creationAccountError"/>, {
            variant: "error",
          });
        });
    } catch (error) {
      closeSnackbar("loading");
      setLoading(false);

      enqueueSnackbar(<FormattedMessage id="errorSendingImg"/>, {
        variant: "error",
      });
      return;
    }
  };
  return (
    <>
      {user?.isPro && <Redirect to="/home" />}
      <div className={classes.wrapper}>
        <Header />
        <div className={classes.container}>
          <h1 className={classes.title}><FormattedMessage id="createProAccount"/></h1>
          <Paper>
            <form className={classes.form} onSubmit={onSubmit}>
              <input
                accept=".jpg, .jpeg, .png"
                style={{ display: "none" }}
                id="contained-button-file"
                type="file"
                onChange={handleImage}
              />
              <div className={classes.flexCenter}>
                <label
                  htmlFor="contained-button-file"
                  className={classes.flexCenter}
                >
                  <Avatar
                    className={classes.avatar}
                    src={image.imagePreviewUrl}
                  />
                  <p><FormattedMessage id="downloadYourPhoto"/></p>
                </label>
              </div>
              <div className={classes.pseudo}>
                <ProInput
                  label={<FormattedMessage id="indicatePseudo"/>}
                  min={4}
                  onChange={handlePseudoChange}
                  value={infos.username}
                  required
                />
                {!!usernameError && (
                  <p style={{ color: "tomato", marginLeft: 5 }}>
                    {usernameError}
                  </p>
                )}
                {avaliable && (
                  <p style={{ color: "green", marginLeft: 5 }}>
                    <FormattedMessage id="pseudoAvailable"/>
                  </p>
                )}
              </div>
              <ProInput
                label={<FormattedMessage id="describeYourself"/>}
                multiline
                fullWidth
                onChange={(e) => {
                  setInfos({ ...infos, description: e.target.value });
                }}
                required
              />
              <ProInput
                label={<FormattedMessage id="whatTypeOfActivity"/>}
                onChange={(e) => {
                  setInfos({ ...infos, activityType: e.target.value });
                }}
                required
              />
              <ProInput
                label={<FormattedMessage id="indicateYourWebsite"/>}
                onChange={(e) => {
                  setInfos({ ...infos, website: e.target.value });
                }}
                required
              />

              <div className={classes.flexCenter}>
                <ProCheckbox
                  label={<FormattedMessage id="iCertifyInfos"/>}
                  required
                />
                <ProCheckbox
                  required
                  label="J’accepte la charte éthique et professionnelle 
                    ainsi que les conditions générales Codjy"
                />
                {!loading ? (
                  <Button
                    className={classes.button}
                    color="secondary"
                    variant="contained"
                    type="submit"
                  >
                    <FormattedMessage id="validateInfos"/>
                  </Button>
                ) : (
                  <CircularProgress color="secondary" />
                )}
              </div>
            </form>
          </Paper>
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: "100vh",
    backgroundColor: "#261170",
    padding: 10,
    "& label": {
      cursor: "pointer",
    },
  },
  headerFlex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  container: {
    width: "80%",
    margin: "50px auto",
    color: "#FFF",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "flex-start",
    borderRadius: 30,
    padding: 30,
    "& p": {
      color: "#8A94A6",
    },
  },
  flexCenter: {
    alignSelf: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    margin: 20,
  },
  avatar: {
    width: 80,
    height: 80,
  },
  doubleInput: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    minWidth: "50%",
  },
  subtitle: {
    fontWeight: "bold",
    color: "#8A94A6",
    fontSize: 20,
  },
  links: {
    color: "#FFF",
    textDecoration: "unset",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  button: {
    borderRadius: 50,
    textTransform: "none",
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: 16,
    marginTop: 10,
  },
  pseudo: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
    "& label": {
      display: "block",
      width: "100%",
    },
  },
}));

export default CreateProAccount;
