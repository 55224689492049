import React, { useState, useEffect, useContext } from "react";
import { makeStyles, Grid, Button, Avatar, Hidden } from "@material-ui/core";
import Rectangle from "../../img/Rectangle.png";
import face from "../../img/face.png";
import { useHistory, Link, useParams } from "react-router-dom";
import Header from "../Header";
import RequestAPI, { RequestAPIAuth } from "../../Links";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/nl";
import { getImageUrl } from "../../Firebase/config";
import { FirebaseContext } from "../../Firebase/FirebaseContext";
import ActivityPreview from "../Home/HomeComponents/ActivityPreview";
import { useSnackbar } from "notistack";
import { FormattedMessage, useIntl } from "react-intl";

/* 
{`${moment
  .duration(moment(activity.endDate).diff(activity.startDate))
  .asMinutes()}
 minutes`} */

const Activity = () => {
  const classes = useStyles();
  const intl = useIntl();
  const [places, setPlaces] = useState(() => {
    const localData = localStorage.getItem("places");
    return localData ? localData : "";
  });
  const [activities, setActivities] = useState(() => {
    const localData = localStorage.getItem("activities");
    return localData ? JSON.parse(localData) : [];
  });
  const [index, setIndex] = useState(-1);
  const [events, setEvents] = useState([]);
  const [query, setQuery] = useState("");
  const [filters, setFilters] = useState({
    category: [],
    type: [],
    public: [],
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [event, setEvent] = useState({});
  const { id } = useParams();
  const { firebaseUser } = useContext(FirebaseContext);

  let time = moment;
  time.locale(intl.locale);

  const withFilters = (event) => {
    if (id === event._id) {
      return false;
    }

    return moment(event.startDate).diff(moment(), "day", true) > 0;
  };

  useEffect(() => {
    if (event.activity?.author) {
      RequestAPI("GET", "event?pro=" + event.activity?.author._id).then(
        (res) => {
          setEvents(res.data.events);
          console.log("WORKS ----");
        }
      );
    }
  }, [event]);

  useEffect(() => {
    setIndex(activities.findIndex((element) => element._id === id));
  }, [activities, id]);

  useEffect(() => {
    const getEvents = async (id) => {
      const token = await firebaseUser.getIdToken();
      RequestAPIAuth("GET", "event/" + id, null, token)
        .then((res) => {
          setEvent(res.data.event);
        })
        .catch(() => {
          console.log("WORKS NOT ----");
          enqueueSnackbar(<FormattedMessage id="eventNotFound" />, {
            variant: "error",
          });
          history.push("/home");
        });
    };
    getEvents(id);
  }, [id]);

  useEffect(() => {
    localStorage.setItem("places", places);
  }, [places]);
  const history = useHistory();
  return (
    <div className={classes.wrapper}>
      <Header color="#9B9B9B" />
      <div className={classes.container}>
        <div className={classes.actions}>
          <Link to="/home" className={classes.links}>
            <FormattedMessage id="backToSearchResult" />
          </Link>
          {index >= 0 && (
            <div className={classes.flexbox}>
              {index > 0 && (
                <Link
                  to={`/activity/${activities[index - 1]._id}`}
                  className={classes.links}
                >
                  <FormattedMessage id="previous" />
                </Link>
              )}

              {index < activities.length - 1 && (
                <Link
                  to={`/activity/${activities[index + 1]._id}`}
                  className={classes.links}
                  style={{ marginLeft: 5 }}
                >
                  <FormattedMessage id="next" />
                </Link>
              )}
            </div>
          )}
        </div>
        <Grid container>
          <Grid xs={12} md={8}>
            <img
              src={
                !!event?.activity?.picture
                  ? getImageUrl(event?.activity?.picture)
                  : Rectangle
              }
              alt="activity"
              className={classes.banner}
            />
            <Grid xs={12}>
              <p style={{ textTransform: "capitalize" }}>
                {event?.activity?.type}
              </p>
            </Grid>

            <Grid xs={12}>
              <h1 className={classes.title}>{event?.activity?.name}</h1>
              <p className={classes.subtitle}>{event?.activity?.subtitle}</p>
              <p>
                <FormattedMessage id="activityDesc" />
              </p>
              <p className={classes.description}>
                {event?.activity?.description}
              </p>
            </Grid>
            <Hidden mdUp>
              <Grid xs={12}>
                <div className={classes.priceContainer}>
                  <h2>
                    {event?.activity?.price?.toFixed(2)
                      ? event?.activity?.price?.toFixed(2) + "€"
                      : "Gratuit"}
                  </h2>
                  <p>{time(event.startDate).format("DD MMMM YYYY")}</p>
                  <p>
                    {time(event.startDate).format("HH[h]mm") +
                      " à " +
                      time(event.endDate).format("HH[h]mm")}
                  </p>
                  <p>
                    <FormattedMessage id="online" />
                  </p>
                  <p className={classes.remainingSeats}>
                    {event.remainingSeats} place(s) restantes
                  </p>
                  <div className={classes.flexbox}>
                    <input
                      value={places}
                      onChange={(e) => {
                        setPlaces(e.target.value);
                      }}
                      className={classes.input}
                      type="number"
                      max={event.remainingSeats}
                    />
                  </div>
                  <Button
                    variant="contained"
                    disableElevation
                    color="secondary"
                    classes={{ root: classes.button }}
                    onClick={() => {
                      history.push(`/activity/${event._id}/purchase`);
                    }}
                  >
                    <FormattedMessage id="reserve" />
                  </Button>
                </div>
              </Grid>
            </Hidden>
            <Grid md={10} xs={12}>
              <div className={classes.presentation}>
                <Avatar
                  src={getImageUrl(event?.activity?.author?.picture)}
                  alt="animator"
                  className={classes.photo}
                />
                <div>
                  <p className={classes.authorName}>
                    <FormattedMessage id="howAmI" />
                  </p>
                  <p className={classes.authorUserName}>
                    <Link
                      to={"/p/" + event?.activity?.author?.username}
                      className={classes.links}
                    >
                      @{event?.activity?.author?.username}
                    </Link>
                  </p>
                  <p className={classes.description}>
                    {event?.activity?.author?.description}
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid md={4} className={classes.relative}>
              <div className={classes.priceContainer}>
                <h2>
                  {" "}
                  {event?.activity?.price?.toFixed(2)
                    ? event?.activity?.price?.toFixed(2) + "€"
                    : "Gratuit"}
                </h2>
                <p>{time(event.startDate).format("DD MMMM YYYY")}</p>
                <p>
                  {time(event.startDate).format("HH[h]mm") +
                    " à " +
                    time(event.endDate).format("HH[h]mm")}
                </p>
                <p>
                  <FormattedMessage id="online" />
                </p>
                <p className={classes.remainingSeats}>
                  {event.remainingSeats} place(s) restantes
                </p>
                <div className={classes.flexbox}>
                  <input
                    value={places}
                    onChange={(e) => {
                      setPlaces(e.target.value);
                    }}
                    className={classes.input}
                    type="number"
                    max={event.remainingSeats}
                  />
                </div>
                <Button
                  variant="contained"
                  disableElevation
                  color="secondary"
                  classes={{ root: classes.button }}
                  onClick={() => {
                    history.push(`/activity/${event._id}/purchase`);
                  }}
                >
                  <FormattedMessage id="reserve" />
                </Button>
              </div>
            </Grid>
          </Hidden>
        </Grid>
      </div>
      <div className={classes.otherEvents}>
        {events
          .filter((event) => withFilters(event))
          .map((event) => {
            return <ActivityPreview activity={event} />;
          })}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: "100vh",
    padding: 10,
  },
  relative: {
    position: "relative",
  },
  headerFlex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  container: {
    width: "80%",
    margin: "50px auto",
    color: "#9B9B9B",
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  flexbox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  links: {
    textDecoration: "unset",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  remainingSeats: { color: "#FF2260" },
  banner: {
    width: "100%",
    height: 400,
    objectFit: "cover",
    marginRight: 10,
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  priceContainer: {
    width: "100%",
    height: 400,
    border: "solid 1px #9B9B9B",
    borderRadius: 15,
    marginLeft: 10,
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
    [theme.breakpoints.up("md")]: {
      position: "sticky",
      top: 50,
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-envenly",
    alignItems: "center",
    color: "#9B9B9B",
  },
  button: {
    borderRadius: 50,
    textTransform: "none",
    fontSize: 16,
    width: 180,
    color: "#FFF",
    marginTop: 10,
  },
  title: {
    fontSize: 24,
    textAlign: "left",
    marginTop: 10,
    fontWeight: "normal",
  },
  subtitle: {
    fontSize: 18,
    marginBottom: 30,
  },
  presentation: {
    backgroundColor: "rgba(216,216,216, 0.18)",
    color: "#9B9B9B",
    borderRadius: 15,
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: 20,
    marginTop: 75,
    [theme.breakpoints.up("md")]: {
      marginLeft: "-20%",
      marginRight: "-20%",
    },
  },
  description: {
    textAlign: "justify",
  },
  photo: {
    marginTop: -40,
    width: 150,
    height: 150,
    marginRight: 10,
  },
  input: {
    height: 30,
    width: 80,
    marginLeft: 5,
    lineHeight: 1.5,
    fontSize: 16,
  },
  authorName: {
    margin: 0,
    fontSize: 20,
    color: "#261170",
  },
  authorUserName: {
    fontSize: 24,
    color: "#FF2260",
    margin: 0,
  },
  otherEvents: {
    display: "flex",
    flexWrap: "wrap",
  },
}));
export default Activity;
