import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { FirebaseContext } from '../Firebase/FirebaseContext';

const PrivateRoute =  ({ children, ...rest }) => {
    const {firebaseUser} = useContext(FirebaseContext);
     return (
       <Route
         {...rest}
         render={({ location }) =>
           firebaseUser ? (
             children
           ) : (
             <Redirect
               to={{
                 pathname: "/login",
                 state: { from: location },
               }}
             />
           )
         }
       />
     );
   }

 
export default PrivateRoute;