import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, makeStyles, Avatar } from "@material-ui/core";
import face from "../../img/face.png";
import Rectangle from "../../img/Rectangle.png";
import Header from "../Header";
import RequestAPI, { RequestAPIAuth } from "../../Links";
import moment from "moment";
import { useSnackbar } from "notistack";
import { FirebaseContext } from "../../Firebase/FirebaseContext";
import { loadStripe } from "@stripe/stripe-js";
import { getImageUrl } from "../../Firebase/config";
import ClearIcon from "@material-ui/icons/Clear";
import ActivityPreview from "../Home/HomeComponents/ActivityPreview";
import { FormattedMessage } from "react-intl";

const stripePromise = loadStripe(
  "pk_live_51HkQbzBg0eyxbyKZu9vhM3b8CALvyWNTKrpvjfPYiiM1Q3A41GxKNPRBcbatgFXKz4NNscOERAivRIzp6EVkHY0w00WaNfo870"
);

const Purchase = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const [event, setEvent] = useState({});
  const [loading, setLoading] = useState(true);
  const [places] = useState(() => {
    const localData = localStorage.getItem("places");
    return localData ? localData : history.goBack();
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { firebaseUser, user } = useContext(FirebaseContext);

  const checkout = async () => {
    enqueueSnackbar(<FormattedMessage id="redirectingInProgress" />, {
      variant: "info",
      persist: true,
      key: "loading",
    });
    const token = await firebaseUser.getIdToken();

    RequestAPIAuth(
      "POST",
      "reservation",
      {
        seats: places,
        event: id,
      },
      token
    )
      .then(async (res) => {
        localStorage.setItem("cart", id);
        const stripe = await stripePromise;
        const result = await stripe.redirectToCheckout({
          sessionId: res.data.checkoutId,
        });

        if (result.error) {
          closeSnackbar("loading");
          enqueueSnackbar(<FormattedMessage id="redirectingError" />, {
            variant: "error",
          });
        }
        closeSnackbar("loading");
        console.log(res.data);
        enqueueSnackbar("Redirection", {
          variant: "success",
        });
      })
      .catch((err) => {
        closeSnackbar("loading");
        enqueueSnackbar(<FormattedMessage id="redirectingError" />, {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    const getEvent = async () => {
      const token = await firebaseUser.getIdToken();

      RequestAPIAuth("GET", "event/" + id, null, token)
        .then((res) => {
          setEvent(res.data.event);
          setLoading(false);
          if (localStorage.getItem("cart") === id) {
            enqueueSnackbar(<FormattedMessage id="paiementCanceling" />, {
              variant: "error",
            });
            localStorage.removeItem("cart");
          }
        })
        .catch(() => {
          enqueueSnackbar(<FormattedMessage id="eventNotFound" />, {
            variant: "error",
          });
          history.push("/home");
        });
    };
    getEvent();
  }, [id]);
  moment.locale("fr", {
    months:
      "janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre".split(
        "_"
      ),
  });
  let time = moment;
  time.locale("fr");

  return (
    <div className={classes.wrapper}>
      <Header />

      <div className={classes.secondWrapper}>
        {!loading && (
          <ActivityPreview
            className={classes.activityPreview}
            activity={event}
            action={false}
          />
        )}

        <div className={classes.container}>
          {!!event && (
            <>
              <h1 className={classes.title}>{event.activity?.name}</h1>
              <p className={classes.subtitle}>{event.activity?.subtitle}</p>
              <div className={classes.bannerContainer}>
                {/* <img
                  src={getImageUrl(event?.activity?.picture) || Rectangle}
                  alt="activity"
                  className={classes.banner}
                /> */}
                <div>
                  <h2>{event.activity?.price?.toFixed(2) || 0}€</h2>
                  <p>{time(event.startDate).format("DD MMMM YYYY")}</p>
                  <p>
                    {time(event.startDate).format("HH[h]mm") +
                      " à " +
                      time(event.endDate).format("HH[h]mm")}
                  </p>
                  <p>
                    <FormattedMessage id="online" />
                  </p>
                </div>
              </div>
              {/* <div className={classes.teacher}>
                <Avatar
                  src={getImageUrl(event?.activity?.author?.picture) || face}
                  className={classes.photo}
                  variant="circle"
                />
                <p>{`Présenté par : ${
                  event.activity?.author?.username || event.activity?.author?.name
                }`}</p>
              </div> */}
              <div className={classes.greyStroke}></div>
              <div className={classes.listItem}>
                <p>Réservation</p>
                <p>{`x ${places}`}</p>
              </div>
              <div className={classes.listItem}>
                <p>
                  <FormattedMessage id="subTotal" />
                </p>
                <p>{`${places * (event.activity?.price || 0)}€`}</p>
              </div>
              <div className={classes.listItem}>
                <p>
                  <FormattedMessage id="servicefees" />
                </p>
                <p>1€</p>
              </div>
              <div className={classes.listItem}>
                <p>Total</p>
                <p>{`${places * (event.activity?.price || 0) + 1}€`}</p>
              </div>
              <Button
                color="secondary"
                variant="contained"
                classes={{ root: classes.button }}
                onClick={() => {
                  checkout();
                }}
              >
                <FormattedMessage id="reserve" />
              </Button>
            </>
          )}

          <p className={classes.linkPolitique}>Politique d'annulation</p>
          <p
            className={classes.linkAnnuler}
            onClick={() => {
              history.goBack();
            }}
          >
            <ClearIcon className={classes.crossIcon} />
            <FormattedMessage id="cancel" />
          </p>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: "100vh",
    backgroundColor: "#261170",
    padding: 10,
  },
  secondWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  headerFlex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  container: {
    width: "30%",
    margin: 10,
    color: "#FFF",
    backgroundColor: "#FFF",
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-envenly",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    padding: "25px 0",
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexbox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  links: {
    color: "#FF2260",
    textDecoration: "unset",
    marginBottom: 10,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  linkAnnuler: {
    color: "#FF2260",
    textDecoration: "unset",
    marginBottom: 10,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  linkPolitique: {
    color: "#9b9b9b",
    textDecoration: "unset",
    marginBottom: 10,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  crossIcon: {
    marginTop: 3,
    fontSize: 18,
    marginLeft: 3,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  bannerContainer: {
    width: "157%",
    display: "flex",
    justifyContent: "space-evenly",
    padding: 10,
    "& div": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      alignItems: "left",
      width: "45%",
      "& h2": {
        fontSize: 22,
        margin: 2,
        color: "#261170",
      },
      "& p": {
        fontSize: 14,
        margin: 2,
        color: "#9b9b9b",
      },
    },
  },
  banner: {
    width: "45%",
    height: 120,
    objectFit: "cover",
    marginRight: 10,
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
    color: "#9b9b9b",
  },
  button: {
    borderRadius: 50,
    textTransform: "none",
    fontSize: 16,
    width: 180,
    marginTop: 20,
    marginBottom: 10,
  },
  title: {
    width: "70%",
    fontSize: 28,
    textAlign: "left",
    marginTop: 10,
    fontWeight: "normal",
    marginBottom: 0,
    color: "#f50057",
    fontWeight: "bold",
  },
  subtitle: {
    width: "70%",
    fontSize: 18,
    marginBottom: 10,
    marginTop: 5,
    color: "#9b9b9b",
  },
  presentation: {
    marginLeft: 30,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
    color: "#9b9b9b",
  },
  description: {
    textAlign: "justify",
    color: "#9b9b9b",
  },
  photo: {
    width: 80,
    height: 80,
  },
  input: {
    height: 30,
    width: 80,
    marginLeft: 5,
    lineHeight: 1.5,
    fontSize: 16,
  },
  teacher: {
    width: "80%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    borderBottomWidth: 2,
    borderBottomColor: "#FFF",
    borderBottom: "solid",
    padding: 10,
    marginBottom: 50,
  },
  listItem: {
    width: "70%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "& p": {
      margin: "2px 0px",
    },
    color: "#9b9b9b",
  },
  greyStroke: {
    width: "70%",
    height: 2,
    backgroundColor: "#9b9b9b",
    margin: "25px 0",
  },
}));
export default Purchase;
