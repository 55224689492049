import React, { useContext, useEffect, useState } from "react";
import {
  makeStyles,
  Button,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Hidden,
  Drawer,
} from "@material-ui/core";
import Sidebar from "./Sidebar";
import AddIcon from "@material-ui/icons/Add";
import { CustomTableCell, CustomTableRow } from "../../CustomTable";
import { Link, useHistory } from "react-router-dom";
import RequestAPI, { RequestAPIAuth } from "../../Links";
import { FirebaseContext } from "../../Firebase/FirebaseContext";
import { useSnackbar } from "notistack";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/nl";
import { getImageUrl } from "../../Firebase/config";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import MenuIcon from "@material-ui/icons/Menu";
import { FormattedMessage, useIntl } from "react-intl";

const Activities = () => {
  const [activities, setActivities] = useState([]);
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const { user, firebaseUser } = useContext(FirebaseContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [cancel, setCancel] = useState(false);
  const intl = useIntl();

  const handleCancel = async () => {
    const token = await firebaseUser.getIdToken();
    RequestAPIAuth("DELETE", `activity/${cancel}`, null, token)
      .then((res) => {
        console.log(res);
        setCancel(false);
        setActivities((prev) => prev.filter((acti) => acti._id !== cancel));
        enqueueSnackbar(<FormattedMessage id="eventCanceled" />, {
          variant: "success",
        });
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    const getEvents = async () => {
      const token = await firebaseUser.getIdToken();
      RequestAPI("GET", "activity?pro=" + user._id, null, token).then((res) => {
        setActivities(res.data.activities);
      });
    };
    getEvents();
  }, []);
  let time = moment;
  time.locale(intl.locale);
  return (
    <div className={classes.wrapper}>
      {/* Desktop disposition */}
      <Hidden smDown>
        <Sidebar />
        <div className={classes.container}>
          <div className={classes.subheader}>
            <h1 className={classes.greetings}>{`Welcome, ${
              user.name || user.email.substring(0, user.email.indexOf("@"))
            }`}</h1>
            <div style={{ display: "flex" }}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.addButton}
                onClick={() => history.push("/pro/createactivity")}
                endIcon={<AddIcon />}
              >
                <FormattedMessage id="postActivity" />
              </Button>
            </div>
          </div>
          <h2 className={classes.subtitle}>
            <FormattedMessage id="yourProCards" />
          </h2>
          <div className={classes.cardContainer}>
            <Card
              className={classes.addActivity}
              onClick={() => history.push("/pro/createactivity")}
            >
              <AddCircleOutlinedIcon color="secondary" fontSize="inherit" />
              <p>
                <FormattedMessage id="createActivityCard" />
              </p>
            </Card>
            {activities.map((activity) => {
              return (
                <Card className={classes.activity}>
                  <CardMedia
                    image={getImageUrl(activity.picture)}
                    className={classes.picture}
                  />
                  <CardContent className={classes.cardContent}>
                    <p className={classes.activityTitle}>{activity.name}</p>
                    <p className={classes.activitySubtitle}>
                      {activity.subtitle}
                    </p>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      className={classes.button}
                      onClick={() => {
                        history.push(`/pro/event/${activity._id}`);
                      }}
                    >
                      <FormattedMessage id="newEvent" />
                    </Button>
                    <Button
                      size="small"
                      className={classes.button}
                      onClick={() => {
                        setCancel(activity._id);
                      }}
                    >
                      <FormattedMessage id="delete" />
                    </Button>
                  </CardActions>
                </Card>
              );
            })}
          </div>
        </div>
        <Dialog open={!!cancel} onClose={() => setCancel(false)}>
          <DialogContent className={classes.dialogFlex}>
            <DialogContentText>
              <FormattedMessage id="confirmCancelingEvent" />
            </DialogContentText>
            <Button className={classes.dialogConfirm} onClick={handleCancel}>
              <FormattedMessage id="iConfirm" />
            </Button>
            <Link to="#">
              <FormattedMessage id="seeCancelingPoilitics" />
            </Link>
          </DialogContent>
        </Dialog>
      </Hidden>

      {/* Mobile disposition */}
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          classes={{
            root: { minWidth: 200 },
          }}
        >
          <Sidebar />
        </Drawer>

        <div className={classes.menuSideBar}>
          <MenuIcon
            className={classes.menuIcon}
            onClick={() => {
              setOpen(true);
            }}
          />
        </div>

        <div className={classes.container}>
          <div className={classes.subheader}>
            <h1 className={classes.greetings}>{`Welcome, ${
              user.name || user.email.substring(0, user.email.indexOf("@"))
            }`}</h1>
            <div style={{ display: "flex" }}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.addButton}
                onClick={() => history.push("/pro/createactivity")}
                endIcon={<AddIcon />}
              >
                <FormattedMessage id="postActivity" />
              </Button>
            </div>
          </div>
          <h2 className={classes.subtitle}>
            <FormattedMessage id="yourProCards" />
          </h2>
          <div className={classes.cardContainer}>
            <Card
              className={classes.addActivity}
              onClick={() => history.push("/pro/createactivity")}
            >
              <AddCircleOutlinedIcon color="secondary" fontSize="inherit" />
              <p>
                <FormattedMessage id="createActivityCard" />
              </p>
            </Card>
            {activities.map((activity) => {
              return (
                <Card className={classes.activity}>
                  <CardMedia
                    image={getImageUrl(activity.picture)}
                    className={classes.picture}
                  />
                  <CardContent className={classes.cardContent}>
                    <p className={classes.activityTitle}>{activity.name}</p>
                    <p className={classes.activitySubtitle}>
                      {activity.subtitle}
                    </p>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      className={classes.button}
                      onClick={() => {
                        history.push(`/pro/event/${activity._id}`);
                      }}
                    >
                      <FormattedMessage id="newEvent" />
                    </Button>
                    <Button
                      size="small"
                      className={classes.button}
                      onClick={() => {
                        setCancel(activity._id);
                      }}
                    >
                      <FormattedMessage id="delete" />
                    </Button>
                  </CardActions>
                </Card>
              );
            })}
          </div>
        </div>
        <Dialog open={!!cancel} onClose={() => setCancel(false)}>
          <DialogContent className={classes.dialogFlex}>
            <DialogContentText>
              <FormattedMessage id="confirmCancelingEvent" />
            </DialogContentText>
            <Button className={classes.dialogConfirm} onClick={handleCancel}>
              <FormattedMessage id="iConfirm" />
            </Button>
            <Link to="#">
              <FormattedMessage id="seeCancelingPoilitics" />
            </Link>
          </DialogContent>
        </Dialog>
      </Hidden>
    </div>
  );
};
const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
  },
  container: {
    width: "70%",
    padding: "40px 20px",
  },
  subheader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cardContainer: {
    display: "flex",
    alignItems: "stretch",
    flexWrap: "wrap",
  },
  greetings: {
    color: "#FF2260",
    fontWeight: "normal",
  },
  button: {
    textTransform: "none",
    color: "#8A94A6",
  },
  activitySubtitle: {
    color: "#8A94A6",
    fontSize: 16,
    fontStyle: "italic",
    margin: 0,
  },
  subtitle: {
    color: "#8A94A6",
    fontSize: 16,
    fontWeight: "normal",
  },
  activityTitle: {
    color: "#8A94A6",
    fontSize: 20,
    margin: 0,
    fontWeight: "700",
  },
  activity: {
    minWidth: 250,
    width: "30%",
    minHeight: 300,
    margin: 5,
    display: "flex",
    flexDirection: "column",
  },
  addActivity: {
    minWidth: 250,
    width: "30%",
    margin: 5,
    minHeight: 300,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    fontSize: 70,
    cursor: "pointer",
    "& p": {
      color: "#FF2260",
      textAlign: "center",
      margin: 20,
      fontSize: 20,
    },
  },
  cardContent: {
    flexGrow: 1,
  },
  addButton: {
    textTransform: "none",
    borderRadius: 25,
  },
  picture: {
    height: 175,
  },
  dialogFlex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  dialogConfirm: {
    color: "#FF2260",
    textTransform: "none",
  },
  cancelButton: {
    textTransform: "none",
    color: "#9B9B9B",
  },
  joinButton: {
    textTransform: "none",
    color: "#FF2260",
  },
  menuSideBar: {
    width: 75,
    height: 80,
    position: "sticky",
    top: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    color: "#f50057",
    paddingTop: 65,
  },
  menuIcon: {
    height: "1.65em",
    width: "1.65em",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
export default Activities;
