import React, { useState, useEffect, useContext } from "react";
import { makeStyles, Button, Paper, Avatar } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Header from "../../Header";
import ProInput from "../../Inputs/ProInput";
import ProCheckbox from "../../Inputs/ProCheckbox";
import camera from "../../../img/camera.svg";
import ProSelect from "../../Inputs/ProSelect";
import CurrencyInput from "react-money-input";
import { RequestAPIAuth } from "../../../Links";
import { FirebaseContext } from "../../../Firebase/FirebaseContext";
import { useSnackbar } from "notistack";
import moment from "moment";
import { uploadFile } from "../../../Firebase/config";
import { FormattedMessage } from "react-intl";

const CreateActivity = () => {
  const classes = useStyles();
  const history = useHistory();
  const [image, setImage] = useState({});
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [infos, setInfos] = useState({});
  const [eventLater, setEventLater] = useState(false);
  const { firebaseUser, user } = useContext(FirebaseContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleImage = (e) => {
    console.log("hey");
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setImage({ file: file, imagePreviewUrl: reader.result });
    };
    console.log(file);
    reader.readAsDataURL(file);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    enqueueSnackbar(<FormattedMessage id="loading"/>, {
      variant: "info",
      key: "loading",
    });
    const token = await firebaseUser.getIdToken();
    const filename = `activities/${user._id}_${new Date().getTime()}`;
    try {
      const snapshot = await uploadFile(filename, image.file);
      enqueueSnackbar(<FormattedMessage id="photoSent"/>, {
        variant: "info",
      });
      console.log(snapshot);
      console.log(filename);
      setLoading(false);
      RequestAPIAuth(
        "POST",
        "activity",
        {
          ...infos,
          date: moment(`${infos.date} ${infos.time}`, "YYYY-MM-DD hh:mm"),
          picture: filename,
        },
        token
      )
        .then((res) => {
          closeSnackbar("loading");
          console.log(res.data);
          enqueueSnackbar(<FormattedMessage id="activityCreated"/>, {
            variant: "success",
          });
          eventLater
            ? history.push("/pro/activities")
            : history.push("/pro/event/" + res.data.activity._id);
        })
        .catch((err) => {
          setLoading(false);
          closeSnackbar("loading");
          enqueueSnackbar(<FormattedMessage id="activityCreationError"/>, {
            variant: "error",
          });
        });
    } catch (error) {
      closeSnackbar("loading");
      setLoading(false);

      enqueueSnackbar(<FormattedMessage id="errorSendingImg"/>, {
        variant: "error",
      });
    }
  };
  return (
    <div className={classes.wrapper}>
      <Header />
      <form className={classes.container} onSubmit={onSubmit}>
        <h1 className={classes.title}><FormattedMessage id="createActivity"/></h1>
        <Paper className={classes.form}>
          <input
            accept=".jpg, .jpeg, .png"
            style={{ display: "none" }}
            id="contained-button-file"
            type="file"
            onChange={handleImage}
          />
          <ProInput
            label="Nom de l’activité : Exemple « cours de yoga débutant… » "
            fullWidth
            onChange={(e) => {
              setInfos({ ...infos, name: e.target.value });
            }}
            required
          />
          <ProInput
            label="Sous-titre de l’activité : Exemple « apprendre ensemble les bases de… »"
            fullWidth
            onChange={(e) => {
              setInfos({ ...infos, subtitle: e.target.value });
            }}
            required
          />
          <ProInput
            label={<FormattedMessage id="describeActivity"/>}
            multiline
            fullWidth
            onChange={(e) => {
              setInfos({ ...infos, description: e.target.value });
            }}
            required
          />
          <p><FormattedMessage id="addHerePhotoForActivity"/></p>

          <label htmlFor="contained-button-file" className={classes.picture}>
            {!!image.imagePreviewUrl ? (
              <img
                src={image.imagePreviewUrl}
                alt="CodjyLive"
                className={classes.imagePreview}
              />
            ) : (
              <img src={camera} alt="CodjyLive" className={classes.logo} />
            )}
          </label>
          <ProSelect
            label="Quel est le type d’activité que vous allez proposer ?"
            onChange={(e) => {
              setInfos({ ...infos, type: e.target.value });
            }}
            required
          >
            <option value="" placeholder>
              <FormattedMessage id="selectType"/>
            </option>
            <option value="coaching">Coaching</option>
            <option value="cours">Cours</option>
            <option value="atelier"><FormattedMessage id="workshop"/></option>
          </ProSelect>
          <ProSelect
            label="Dans quelle catégorie se situe votre activité ?"
            onChange={(e) => {
              setInfos({ ...infos, category: e.target.value });
            }}
            required
          >
            <option value="" placeholder>
              <FormattedMessage id="selectCategory"/>
            </option>
            <option value="yoga">Yoga</option>
            <option value="langues">Langues</option>
            <option value="lifestyle">Lifestyle</option>
            <option value="developpement"><FormattedMessage id="persoDev"/></option>
            <option value="sport">Sport</option>
            <option value="nutrition">Nutrition</option>
            <option value="bienetre"><FormattedMessage id="wellBeing"/></option>
            <option value="creatif"><FormattedMessage id="creativeHobbies"/></option>
            <option value="danse"><FormattedMessage id="dance"/></option>
            <option value="culture">Culture</option>
            <option value="musique"><FormattedMessage id="music"/></option>
            <option value="nature"><FormattedMessage id="natureAndPlants"/></option>
            <option value="informatique"><FormattedMessage id="informatic"/></option>
            <option value="scolaire"><FormattedMessage id="schoolTutoring"/></option>
          </ProSelect>
          <ProSelect
            label="Pour quel public est destiné votre activité ?"
            onChange={(e) => {
              setInfos({ ...infos, public: e.target.value });
            }}
            required
          >
            <option value="" placeholder>
              <FormattedMessage id="selectPublic"/>
            </option>
            <option value="adulte">Adulte</option>
            <option value="couple"><FormattedMessage id="couplePriceForTwo"/></option>
            <option value="senior">Senior</option>
            <option value="enfant"><FormattedMessage id="parentsAndChild"/></option>
            <option value="junior">Junior</option>
          </ProSelect>
          {/*  <ProInput
            label="A quelle date a lieu votre activité ?"
            type="date"
            onChange={(e) => {
              setInfos({ ...infos, date: e.target.value });
            }}
            required
          />
          <ProInput
            label="A quelle heure a lieu votre activité ?"
            type="time"
            onChange={(e) => {
              setInfos({ ...infos, time: e.target.value });
            }}
            required
          />
          <ProInput
            label="Combien de temps dure votre activité ? (en minutes)"
            onChange={(e) => {
              setInfos({ ...infos, duration: e.target.value });
            }}
            required
            type="number"
            min="0"
          /> */}
          <p className={classes.label}>
            <FormattedMessage id="whatIsActivityPrice"/>
          </p>
          <CurrencyInput
            className={classes.input}
            currencyDisplay="symbol"
            locale="fr-FR"
            onChange={(e, value) => {
              setInfos({ ...infos, price: value });
            }}
            required
          />

          {/* <ProInput
            label="Combien de places sont disponibles pour votre activité ?"
            type="number"
            min="0"
            onChange={(e) => {
              setInfos({ ...infos, seats: e.target.value });
            }}
            required
          /> */}
          <div className={classes.onlineContainer}>
            <p className={classes.label}>Activité en ligne ? :</p>
            <div className={classes.onlineForm}>
              <ProCheckbox
                label="Oui"
                checked={infos.isOnline === true}
                onChange={() => {
                  setInfos({ ...infos, isOnline: true });
                }}
              />
              <ProCheckbox
                label="Non"
                checked={infos.isOnline === false}
                onChange={() => {
                  setInfos({ ...infos, isOnline: false });
                }}
              />
            </div>
          </div>
          {infos.isOnline === false && (
            <>
              <p><FormattedMessage id="activityAddress"/></p>

              <ProInput
                placeholder="N°"
                inputStyles={{
                  minWidth: "20%",
                  width: "20%",
                  marginRight: 5,
                }}
                required
                label="Numéro"
                onChange={(e) => {
                  setInfos({
                    ...infos,
                    address: { ...infos.address, number: e.target.value },
                  });
                }}
              />
              <ProInput
                placeholder="Rue"
                required
                label="Rue"
                onChange={(e) => {
                  setInfos({
                    ...infos,
                    address: { ...infos.address, street: e.target.value },
                  });
                }}
              />
              <ProInput
                placeholder="Ville"
                required
                label="Ville"
                onChange={(e) => {
                  setInfos({
                    ...infos,
                    address: { ...infos.address, city: e.target.value },
                  });
                }}
              />
              <ProInput
                placeholder="CP"
                required
                label="Code Postal"
                onChange={(e) => {
                  setInfos({
                    ...infos,
                    address: { ...infos.address, zip: e.target.value },
                  });
                }}
              />
              <ProInput
                placeholder="Pays"
                required
                label="Pays"
                onChange={(e) => {
                  setInfos({
                    ...infos,
                    address: { ...infos.address, country: e.target.value },
                  });
                }}
              />
            </>
          )}
          <div className={classes.flexCenter}>
            <ProCheckbox
              required
              label="J’accepte la charte éthique 
                et professionnelle Codjy"
            />

            <Button
              className={classes.button}
              color="secondary"
              variant="contained"
              type="submit"
            >
              Valider et ajouter un évènement
            </Button>
            <Button
              className={classes.button}
              color="secondary"
              variant="contained"
              type="submit"
              onClick={() => setEventLater(true)}
            >
              Valider et ne pas ajouter d'évènement
            </Button>
          </div>
        </Paper>
      </form>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: "100vh",
    backgroundColor: "#261170",
    padding: 10,
  },
  headerFlex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  container: {
    width: "80%",
    margin: "50px auto",
    color: "#FFF",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "flex-start",
    borderRadius: 30,
    padding: 30,
    "& p": {
      color: "#8A94A6",
    },
  },
  flexCenter: {
    alignSelf: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    margin: 20,
  },
  onlineContainer: {
    display: "flex",
  },
  onlineForm: {
    marginTop: -5,
    marginLeft: 15,
  },
  picture: {
    alignSelf: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    margin: 20,
    border: "solid",
    borderWidth: 1,
    borderRadius: 16,
    borderColor: "#979797",
    maxWidth: 400,
    height: 200,
    width: "50%",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  imagePreview: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  avatar: {
    width: 80,
    height: 80,
  },
  doubleInput: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    minWidth: "50%",
  },
  subtitle: {
    fontWeight: "bold",
    color: "#8A94A6",
    fontSize: 20,
  },
  links: {
    color: "#FFF",
    textDecoration: "unset",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  button: {
    borderRadius: 50,
    textTransform: "none",
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: 16,
    marginTop: 10,
  },
  input: {
    minWidth: "48%",
    backgroundColor: "#FFF",
    borderWidth: "1px",
    borderColor: "#979797",
    borderStyle: "solid",
    borderRadius: 15,
    padding: "5px 10px",
    margin: "10px 0",
    boxShadow: "0 2px 5px rgba(0,0,0,0.5)",
    height: "30px",
  },
  label: {
    color: "#979797",
    marginTop: 5,
    marginBottom: 0,
  },
}));

export default CreateActivity;
