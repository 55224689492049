import React, { useState } from "react";
import { makeStyles, Collapse } from "@material-ui/core";
import logo from "../../img/logo.svg";
import { useHistory, useLocation } from "react-router-dom";
import BarChartIcon from "@material-ui/icons/BarChart";
import PersonIcon from "@material-ui/icons/Person";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { FormattedMessage } from "react-intl";

const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation()

  const colors =
    location.pathname.includes("pro")
      ? {
          backgroundColor: "#261170",
          iconColor: "#FF2260",
          selectedColor: "#FF2260",
        }
      : {
          backgroundColor: "#FF2260",
          iconColor: "#261170",
          selectedColor: "#FFF",
        };

  return (
    <div className={classes.wrapper}>
      <div
        className={classes.container}
        style={{ backgroundColor: colors.backgroundColor }}
      >
        <img
          src={logo}
          alt="CodjyLive"
          className={classes.logo}
          onClick={() => {
            history.push("/home");
          }}
        />
        <div
          className={classes.menuItem}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <div
            className={classes.iconContainer}
            style={{
              backgroundColor: location.pathname.includes("dashboard")
                ? colors.iconColor
                : colors.backgroundColor,
            }}
          >
            <BarChartIcon className={classes.icon} />
          </div>
          <p
            style={{
              color: location.pathname.includes("dashboard")
                ? colors.selectedColor
                : "#FFF",
            }}
          >
            Dashboard
          </p>
          <ChevronRightIcon
            style={{
              transform: open ? "rotate(90deg)" : "none",
              color: open ? colors.selectedColor : "inherit",
            }}
            className={classes.chevron}
          />
        </div>
        <Collapse in={open}>
          <div className={classes.dashboardSelector}>
            <div>
              <p
                style={{
                  color: location.pathname.includes("dashboard")
                    ? colors.selectedColor
                    : "#FFF",
                }}
                onClick={() => {
                  history.push("/pro/dashboard");
                }}
              >
                PRO
              </p>
            </div>
            <div>
              <p
                onClick={() => {
                  history.push("/dashboard");
                }}
              >
                Particulier
              </p>
            </div>
          </div>
        </Collapse>
        <div
          className={classes.menuItem}
          onClick={() => {
            history.push("/account");
          }}
        >
          <div
            className={classes.iconContainer}
            style={{
              backgroundColor: location.pathname.includes("account")
                ? colors.iconColor
                : colors.backgroundColor,
            }}
          >
            <PersonIcon className={classes.icon} />
          </div>
          <p
            style={{
              color: location.pathname.includes("account")
                ? colors.selectedColor
                : "#FFF",
            }}
          >
            <FormattedMessage id="myAccount"/>
          </p>
          <ChevronRightIcon className={classes.chevron} />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  wrapper: {
    width: "25%",
    minWidth: 200,
    height: "100vh",
    position: "sticky",
    top: 0,
  },
  container: {
    height: "100vh",
    width: "100%",
    borderBottomRightRadius: 0,
    display: "flex",
    flexDirection: "column",
    color: "#FFF",
  },
  logo: {
    margin: 20,
    alignSelf: "center",
    width: 120,
    height: 120,
    cursor: "pointer",
  },
  menuItem: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    cursor: "pointer",
    paddingRight: 20,
  },
  iconContainer: {
    padding: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
  },
  icon: {
    color: "#FFF",
    width: 30,
    height: 30,
  },
  dashboardSelector: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "left",
    "& div": {
      width: 80,
      marginLeft: 20,
      cursor: "pointer",
    },
  },
  chevron : {
    transition : "ease 200ms"
  }
});
export default Sidebar;
