import React from "react";
import { makeStyles, Input, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

const Searchbar = ({
  placeholderStyles = {},
  inputStyles = {},
  value,
  onChange = () => {},
  ...props
}) => {
  const classes = useStyles();
  return (
    <>
      <Input
        classes={{
          root: Object.assign(classes.input, inputStyles),
        }}
        style={inputStyles}
        disableUnderline
        value={value}
        {...props}
        onChange={onChange}
        endAdornment={
          <InputAdornment position="end">
            <SearchIcon color="action" fontSize="large"  />
          </InputAdornment>
        }
      />
    </>
  );
};
const useStyles = makeStyles((theme) => ({
  input: {
    minWidth: "40%",
    height :50,
    backgroundColor: "#FFF",
    borderWidth: "1px",
    borderColor: "#979797",
    borderStyle: "solid",
    borderRadius: 50,
    padding: "5px 15px",

  },
  label : {
      color : "#979797",
      marginTop : 5,
  },
  placeholder : {
    color : "#979797",
  }
}));
export default Searchbar;
