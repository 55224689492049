import React from "react";
import { makeStyles, Button, Hidden } from "@material-ui/core";
import { ReactComponent as Logo } from "../img/logo.svg";
import { useHistory } from "react-router-dom";
import Header from "./Header";
import landing from "../img/landing.jpg";
import codjylive from "../img/codjylive.png";
import { FormattedMessage } from "react-intl";

const Landing = () => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <div
      className={classes.wrapper}
      style={{ backgroundImage: `url(${landing})`, backgroundSize: "cover" }}
    >
      <div className={classes.container}>
        <Header hideLogo />
        <Hidden mdDown>
          <Logo className={classes.rectangle} />
        </Hidden>
        <Hidden lgUp>
          <Logo className={classes.logo} />
        </Hidden>
        <img src={codjylive} className={classes.logotitle} />
        <h1 className={classes.title}>
          <FormattedMessage id="yourActivityRepertory"/>
        </h1>
        <div className={classes.flexbox}>
          <Button
            variant="contained"
            classes={{ root: classes.button1 }}
            onClick={() => {
              history.push("/home");
            }}
          >
            <FormattedMessage id="searchActivity"/>
          </Button>
          <Button
            variant="contained"
            classes={{ root: classes.button2 }}
            onClick={() => {
              history.push("/login");
            }}
          >
            <FormattedMessage id="postActivity"/>
          </Button>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    zIndex: 1,
  },
  wrapper: {
    position: "relative",
    width: "100vw",
    height: "100vh",
    backgroundSize: "cover",
  },
  logo: {
    minWidth: 200,
    minHeight: 200,
    filter: "drop-shadow(0 0 16px rgba(0,0,0, .2))",
  },
  logotitle: {
    maxHeight: 300,
    [theme.breakpoints.up("lg")]: {
      marginTop: "20%",
      maxWidth: 300,
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: 300,
    },
    maxWidth: "70%",
    filter: "drop-shadow(0 0 16px rgba(0,0,0, .2))",
  },
  title: {
    marginTop: 20,
    fontSize: 20,
    color: "#FFF",
    textAlign: "center",
    filter: "drop-shadow(0 0 16px rgba(0,0,0, .6))",
  },
  flexbox: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-evenly",
    marginTop: 20,
  },
  button1: {
    borderRadius: 50,
    textTransform: "none",
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: 16,
    width: 200,
    marginRight: 10,
    backgroundColor: "#FFF",
    color: "#FF2260",
    marginBottom: 10,
  },
  button2: {
    borderRadius: 50,
    textTransform: "none",
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: 16,
    width: 200,
    marginRight: 10,
    backgroundColor: "#261170",
    color: "#FFF",
    marginBottom: 10,
  },
  rectangle: {
    position: "absolute",
    top: 20,
    left: -10,
    width: "40%",
    height: "40%",
    minWidth: "200px",
  },
  menuIcon: {
    color: "#FFF",
    padding: 10,
    fontSize: 30,
  },
}));

export default Landing;
