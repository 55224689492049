import React from "react";
import { CookiesProvider } from "react-cookie";
import "./App.css";
import FirebaseContextProvider from "./Firebase/FirebaseContext";
import Routes from "./Routes";
import { SnackbarProvider } from "notistack";
import Footer from "./Components/Footer";
import LocaleContextProvider from "./Wrappers/LocaleContext";

function App() {
  return (
    <LocaleContextProvider>
      <CookiesProvider>
        <FirebaseContextProvider>
          <SnackbarProvider maxSnack={3}>
            <Routes></Routes>
          </SnackbarProvider>
        </FirebaseContextProvider>
      </CookiesProvider>
    </LocaleContextProvider>
  );
}

export default App;
