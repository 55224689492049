import React, { useState, useEffect, useContext } from "react";
import { Hidden, makeStyles } from "@material-ui/core";
import { useParams } from "react-router-dom";
import RequestAPI, { RequestAPIAuth } from "../Links";
import { FirebaseContext } from "../Firebase/FirebaseContext";
import { getImageUrl } from "../Firebase/config";
import Header from "./Header";
import moment from "moment";
import ActivityPreview from "./Home/HomeComponents/ActivityPreview";
import { FormattedMessage } from "react-intl";

const Profile = () => {
  const classes = useStyles();
  const { proName } = useParams();
  const [desc, setDesc] = useState();
  const [picture, setPicture] = useState();
  const [website, setWebsite] = useState();
  const [userId, setUserId] = useState();
  const { firebaseUser } = useContext(FirebaseContext);
  const [events, setEvents] = useState([]);
  const [filters, setFilters] = useState({
    category: [],
    type: [],
    public: [],
  });

  const withFilters = (event) => {
    if (userId === event._id) {
      return false;
    }

    return moment(event.startDate).diff(moment(), "day", true) > 0;
  };
  const withFiltersReverse = (event) => {
    if (userId === event._id) {
      return false;
    }

    return moment(event.startDate).diff(moment(), "day", true) < 0;
  };

  const getEvents = async (userId) => {
    const token = await firebaseUser.getIdToken();
    console.log(userId);
    RequestAPIAuth("GET", "event?pro=" + userId, null, token)
      .then((res) => {
        console.log(res.data.events);
        setEvents(res.data.events);
      })
      .catch((err) => {
        console.log(err.response);
        console.log(<FormattedMessage id= "errorHappened"/>);
      });
  };

  useEffect(() => {
    RequestAPI("GET", "pro/" + proName)
      .then((res) => {
        console.log(res.data.user);
        setPicture(res.data.user.picture);
        setDesc(res.data.user.description);
        setWebsite(res.data.user.website);
        setUserId(res.data.user._id);
        getEvents(res.data.user._id);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <Header color="#9B9B9B" />
      <h2 className={classes.bigTitle}><FormattedMessage id="profileOf"/>{proName}</h2>

      {/* Desktop disposition */}
      <Hidden smDown>
        <div className={classes.wrapper}>
          <div className={classes.infos}>
            <img
              src={picture ? getImageUrl(picture) : ""}
              alt="activity"
              className={classes.proPicture}
            />
            <div className={classes.line}>
              <span className={classes.label}>Nom : </span>
              <h3 className={classes.textValue}>{proName}</h3>
            </div>
            <div className={classes.line}>
              <span className={classes.label}>Site web : </span>
              <h3 className={classes.textValue}>{website}</h3>
            </div>
          </div>
          <div className={classes.infos}>
            <h3 style={{ fontSize: 25, fontWeight: "500", margin: 0 }}>
              <FormattedMessage id="learnMore"/> sur moi
            </h3>
            <div className={classes.line} style={{ marginTop: 25 }}>
              <h3 style={{ margin: 1, fontWeight: "normal" }}>{desc}</h3>
            </div>
          </div>

          <div className={classes.otherEvents}>
            {events
              .filter((event) => {})
              .map((event) => {
                return <ActivityPreview activity={event} />;
              })}
          </div>
        </div>
        
        <h3 className={classes.secondTitle}>Prochains évenements</h3>
        <div className={classes.otherEvents}>
          {events
            .filter((event) => withFilters(event))
            .map((event) => {
              return <ActivityPreview activity={event} />;
            })}
        </div>
        <h3 className={classes.secondTitle}>Évenements passés</h3>
        <div className={classes.otherEvents}>
          {events
            .filter((event) => withFiltersReverse(event))
            .map((event) => {
              return <ActivityPreview activity={event} action={false} />;
            })}
        </div>
      </Hidden>

      {/* Mobile disposition */}
      <Hidden mdUp>
        <div
          className={classes.wrapper}
          style={{ flexDirection: "column", padding: 0, alignItems: "center" }}
        >
          <div className={classes.infos}>
            <img
              src={picture ? getImageUrl(picture) : ""}
              alt="activity"
              className={classes.proPicture}
              style={{
                width: 200,
                height: 200,
              }}
            />
            <div className={classes.line}>
              <span className={classes.label}>Nom : </span>
              <h3 className={classes.textValue}>{proName}</h3>
            </div>
            <div className={classes.line}>
              <span className={classes.label}>Site web : </span>
              <h3 className={classes.textValue}>{website}</h3>
            </div>
          </div>
          <div className={classes.infos} style={{ margin: "30px 25px" }}>
            <h3 style={{ fontSize: 25, fontWeight: "500", margin: 0 }}>
              <FormattedMessage id="learnMore"/> sur moi
            </h3>
            <div className={classes.line} style={{ marginTop: 25 }}>
              <h3 style={{ margin: 1, fontWeight: "normal" }}>{desc}</h3>
            </div>
          </div>
        </div>
        
        <h3 className={classes.secondTitle}>Prochains évenements</h3>
        <div className={classes.otherEvents}>
          {events
            .filter((event) => withFilters(event))
            .map((event) => {
              return <ActivityPreview activity={event} />;
            })}
        </div>
        <h3 className={classes.secondTitle}>Évenements passés</h3>
        <div className={classes.otherEvents}>
          {events
            .filter((event) => withFiltersReverse(event))
            .map((event) => {
              return <ActivityPreview activity={event} action={false} />;
            })}
        </div>
      </Hidden>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: 50,
    flexDirection: "row",
  },
  bigTitle: {
    fontWeight: 400,
    fontSize: 40,
    padding: "15px 50px",
    backgroundColor: "#261170",
    color: "#ffffff",
    boxShadow: "0px 5px 12px -2px rgba(0,0,0,0.23)",
  },
  proPicture: {
    borderRadius: "50%",
    width: 250,
    height: 250,
    objectFit: "cover",
    boxShadow: "0px 5px 12px -2px rgba(0,0,0,0.23)",
    margin: "auto",
    marginBottom: 25,
  },
  infos: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    padding: 50,
    maxWidth: "auto",
    boxShadow: "0px 5px 12px -2px rgba(0,0,0,0.23)",
    borderRadius: 23,
    margin: "0 25px",
  },
  line: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "no-wrap",
  },
  label: {
    marginRight: 15,
    fontSize: 17,
    fontWeight: 50,
    whiteSpace: "nowrap",
  },
  textValue: {
    fontSize: 17,
    margin: 10,
  },
  otherEvents: {
    display: "flex",
    flexWrap: "wrap",
  },
  otherEvents: {
    display: "flex",
    flexWrap: "wrap",
  },
  secondTitle: {
    padding: "15px 50px",
    backgroundColor: "#f50057",
    color: "#ffffff",
    width: "35%",
    fontWeight: 300,
    fontSize: 30,
    borderBottomRightRadius: 80,
  },
}));

export default Profile;
