import React, { createContext, useState, useEffect } from "react";
import RequestAPI from "../Links";
import { Firebase } from "./config";

export const FirebaseContext = createContext();

const FirebaseContextProvider = (props) => {
  const [firebaseUser, setFirebaseUser] = useState(null);
  const [user, setUser] = useState(() => {
    const json = localStorage.getItem("user");
    const data = JSON.parse(json);
    return !!data ? data : null;
  });
  const [pending, setPending] = useState(true);

  useEffect(() => {
    Firebase.auth().onAuthStateChanged((res) => {
      setFirebaseUser(res);
      setPending(false);
    });
  }, []);

  useEffect(() => {
    if (firebaseUser) {
      RequestAPI("GET", `user/${firebaseUser.uid}`)
        .then((res) => {
          setUser(res.data.user);
        })
        .catch((err) => {
          console.log(err?.response?.status)
          if (err.response?.status === 404) {
            RequestAPI("POST", `user`, {
              uid : firebaseUser.uid,
              name : firebaseUser.displayName,
              email : firebaseUser.email
            }).then((res) => {
              setUser(res.data.user);
            });
          }
        });
    }
  }, [firebaseUser]);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  const logout = () => {
    Firebase.auth().signOut();
    setUser(null);
  };
  if (pending) {
    return <>Loading...</>;
  }
  return (
    <FirebaseContext.Provider value={{ firebaseUser, user, logout, setUser }}>
      {props.children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseContextProvider;
