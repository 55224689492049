import React, { useContext, useEffect, useState } from "react";
import {
  makeStyles,
  Button,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  Hidden,
  Drawer,
  DialogTitle,
} from "@material-ui/core";
import Sidebar from "./Sidebar";
import AddIcon from "@material-ui/icons/Add";
import { CustomTableCell, CustomTableRow } from "../../CustomTable";
import { Link, useHistory } from "react-router-dom";
import { FirebaseContext } from "../../Firebase/FirebaseContext";
import RequestAPI, { RequestAPIAuth } from "../../Links";
import moment from "moment";
import { useSnackbar } from "notistack";
import MenuIcon from "@material-ui/icons/Menu";
import { FormattedMessage } from "react-intl";

const ClientDashBoard = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user, firebaseUser } = useContext(FirebaseContext);
  const [reservations, setReservations] = useState([]);
  const [open, setOpen] = useState(false);
  const [addressOpen, setAddressOpen] = useState(false);
  const [cancel, setCancel] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleCancel = async () => {
    const token = await firebaseUser.getIdToken();
    RequestAPIAuth("DELETE", `reservation/${cancel}`, null, token)
      .then((res) => {
        console.log(res);
        setCancel(false);
        setReservations((prev) => prev.filter((resa) => resa._id !== cancel));
        enqueueSnackbar(<FormattedMessage id="canceledReservation" />, {
          variant: "success",
        });
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    const getResas = async () => {
      const token = await firebaseUser.getIdToken();
      RequestAPIAuth("GET", "reservation?user=" + user._id, null, token).then(
        (res) => {
          setReservations(res.data.reservations);
        }
      );
    };
    getResas();
  }, []);
  moment.locale("fr", {
    months:
      "janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre".split(
        "_"
      ),
  });
  let time = moment;
  time.locale("fr");
  return (
    <div className={classes.wrapper}>
      {/* Desktop disposition */}
      <Hidden smDown>
        <Sidebar />
      </Hidden>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          classes={{
            root: { minWidth: 200 },
          }}
        >
          <Sidebar />
        </Drawer>

        <div className={classes.menuSideBar}>
          <MenuIcon
            className={classes.menuIcon}
            onClick={() => {
              setOpen(true);
            }}
          />
        </div>
      </Hidden>
      <div className={classes.container}>
        <div className={classes.subheader}>
          <h1 className={classes.greetings}>{`Welcome, ${
            user.name || user.email.substring(0, user.email.indexOf("@"))
          }`}</h1>
          {user.isPro ? (
            <Button
              variant="contained"
              color="secondary"
              className={classes.addButton}
              onClick={() => {
                history.push("/pro/dashboard");
              }}
            >
              <FormattedMessage id="proSpace" />
            </Button>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              className={classes.addButton}
              endIcon={<AddIcon />}
              onClick={() => {
                history.push("/pro/create");
              }}
            >
              Activer compte PRO
            </Button>
          )}
        </div>
        {reservations.length > 0 ? (
          <>
            <h2 className={classes.subtitle}>
              <FormattedMessage id="hereItIsActivitiesRecap" />
            </h2>
            <Table>
              <TableHead>
                <TableRow>
                  <CustomTableCell>Date</CustomTableCell>
                  <CustomTableCell>
                    <FormattedMessage id="timePeriod" />
                  </CustomTableCell>
                  <CustomTableCell>
                    <FormattedMessage id="place" />
                  </CustomTableCell>
                  <CustomTableCell>
                    <FormattedMessage id="activityType" />
                  </CustomTableCell>
                  <CustomTableCell>
                    <FormattedMessage id="price" />
                  </CustomTableCell>
                  <CustomTableCell>
                    <FormattedMessage id="reservations" />
                  </CustomTableCell>
                  <CustomTableCell></CustomTableCell>
                  <CustomTableCell></CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reservations.map((reservation) => {
                  return (
                    <CustomTableRow key={reservation._id}>
                      <CustomTableCell>
                        {time(reservation.startDate).format("DD MMMM YYYY")}
                      </CustomTableCell>
                      <CustomTableCell>
                        {time(reservation.startDate).format("HH[h]mm")}
                      </CustomTableCell>
                      <CustomTableCell>
                        {reservation?.event?.activity?.isOnline ? (
                          <FormattedMessage id="online" />
                        ) : (
                          reservation?.event.activity?.address?.city
                        )}
                      </CustomTableCell>
                      <CustomTableCell>
                        {reservation?.event?.activity?.name}
                      </CustomTableCell>
                      <CustomTableCell>
                        {reservation?.event?.activity?.price *
                          reservation?.seats}
                        €
                      </CustomTableCell>
                      <CustomTableCell>{reservation.seats}</CustomTableCell>
                      <CustomTableCell>
                        {reservation?.event?.activity?.isOnline ? (
                          <Button
                            className={classes.joinButton}
                            onClick={() => {
                              history.push(
                                `/meeting/${reservation?.event?.activity?.password}/${reservation?.event?._id}`
                              );
                            }}
                          >
                            <FormattedMessage id="join" />
                          </Button>
                        ) : (
                          <Button
                            className={classes.joinButton}
                            onClick={() => {
                              setAddressOpen(
                                reservation?.event?.activity?.address
                              );
                            }}
                          >
                            Voir l'adresse
                          </Button>
                        )}
                      </CustomTableCell>
                      <CustomTableCell>
                        <Button
                          className={classes.cancelButton}
                          onClick={() => {
                            setCancel(reservation._id);
                          }}
                        >
                          <FormattedMessage id="cancel" />
                        </Button>
                      </CustomTableCell>
                    </CustomTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </>
        ) : (
          <h2 className={classes.subtitle}>
            <FormattedMessage id="noActivitiesYet" />
          </h2>
        )}
      </div>
      <Dialog open={!!cancel} onClose={() => setCancel(false)}>
        <DialogContent className={classes.dialogFlex}>
          <DialogContentText>
            <FormattedMessage id="confirmCancelingReservation" />
          </DialogContentText>
          <Button className={classes.dialogConfirm} onClick={handleCancel}>
            <FormattedMessage id="iConfirm" />
          </Button>
          <Link to="#">
            <FormattedMessage id="seeCancelingPoilitics" />
          </Link>
        </DialogContent>
      </Dialog>
      <Dialog open={!!addressOpen} onClose={() => setAddressOpen(false)}>
        <DialogTitle>Adresse de l'évènement</DialogTitle>
        <DialogContent className={classes.dialogFlex}>
          <DialogContentText>
            Numéro et rue : {addressOpen?.number + " " + addressOpen?.street}
            <br />
            CP / Ville : {addressOpen?.zip + " " + addressOpen?.city} <br />
            Pays : {addressOpen?.country}
          </DialogContentText>
          <Button
            className={classes.dialogConfirm}
            onClick={() => setAddressOpen(false)}
          >
            Fermer
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};
const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
  },
  container: {
    width: "70%",
    padding: "40px 20px",
  },
  subheader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  greetings: {
    color: "#FF2260",
    fontWeight: "normal",
  },
  addButton: {
    textTransform: "none",
    borderRadius: 25,
  },
  subtitle: {
    color: "#8A94A6",
    fontSize: 16,
    fontWeight: "normal",
  },
  tableCell: {
    color: "#9B9B9B",
    border: "none",
  },
  tableRow: {
    border: "1px solid #9B9B9B",
    borderRadius: 5,
  },
  tableLink: {
    border: "none",
    display: "flex",
    alignItems: "center",
    color: "#9B9B9B",
  },
  dialogFlex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  dialogConfirm: {
    color: "#FF2260",
    textTransform: "none",
  },
  cancelButton: {
    textTransform: "none",
    color: "#9B9B9B",
  },
  joinButton: {
    textTransform: "none",
    color: "#FF2260",
  },
  menuSideBar: {
    width: 75,
    height: 80,
    position: "sticky",
    top: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    color: "#f50057",
    paddingTop: 65,
  },
  menuIcon: {
    height: "1.65em",
    width: "1.65em",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
export default ClientDashBoard;
