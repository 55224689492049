import React from "react";
import { FormControlLabel, Checkbox, makeStyles } from "@material-ui/core";

const ProCheckbox = ({
  label = "",
  value = "",

  onChange = () => {},
  labelPlacement = "end",
  ...props
}) => {
  const classes = useStyles();
  return (
    <>
      <FormControlLabel
        value={value}
        control={<Checkbox color="secondary" {...props} onChange={onChange} />}
        label={label}
        labelPlacement={labelPlacement}
        classes={{ root: classes.labelRoot, label: classes.label }}
      />
    </>
  );
};
const useStyles = makeStyles((theme) => ({
  labelRoot: {
    width: "60%",
  },
  label: {
    fontSize: "12px",
    color: "#979797",
    textAlign: "justify",
  },
}));
export default ProCheckbox;
