import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Rectangle from "../../../img/Rectangle.png";
import Pricetag from "../../../img/pricetag.png";
import { useHistory } from "react-router-dom";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/nl";
import { getImageUrl } from "../../../Firebase/config";
import { Avatar, Button } from "@material-ui/core";
import { AccessTime, DateRange, LocationOn, Person } from "@material-ui/icons";
import { FormattedMessage, useIntl } from "react-intl";

const ActivityPreview = ({ activity, action = true }) => {
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();
  let time = moment;
  time.locale(intl.locale);
  return (
    <div
      className={classes.container}
      onClick={() => {
        action && history.push("/activity/" + activity._id);
      }}
    >
      <div className={classes.priceTag}>
        {activity.activity.price > 0 ? (
          activity.activity.price + " €"
        ) : (
          <FormattedMessage id="free" />
        )}
      </div>
      <img
        src={
          !!activity.activity.picture
            ? getImageUrl(activity?.activity?.picture)
            : Rectangle
        }
        alt="activity"
        className={classes.photo}
      />
      <div className={classes.details}>
        <p className={classes.title}>{activity.activity.name}</p>
        <p className={classes.subtitle}>{activity.activity.subtitle}</p>
        <div className={classes.activity_details}>
          <div className={classes.flex}>
            <DateRange color="secondary" />
            <p className={classes.text}>
              {time(activity.startDate).format("DD MMMM YYYY")}
            </p>
          </div>
          <div className={classes.flex}>
            <AccessTime color="secondary" />
            <p className={classes.text}>
              {time(activity.startDate).format("HH[H]mm")}
            </p>
          </div>
          <div className={classes.flex}>
            <Person color="secondary" />
            <p className={classes.text}>
              {activity.remainingSeats} <FormattedMessage id="availablePlace" />
            </p>
          </div>
          <div className={classes.flex}>
            <LocationOn color="secondary" />
            <p className={classes.text}>
              {activity.activity.isOnline ? (
                <FormattedMessage id="online" />
              ) : (
                activity.activity?.address?.city
              )}
            </p>
          </div>
        </div>
        {action && (
          <Button
            color="secondary"
            variant="contained"
            classes={{
              root: classes.button,
            }}
          >
            <FormattedMessage id="learnMore" />
          </Button>
        )}
      </div>
      <div className={classes.user}>
        <Avatar
          className={classes.avatar}
          src={getImageUrl(activity.activity.author.picture)}
        />
        <p>{`@${activity.activity.author.username}`}</p>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    minWidth: 300,
    maxWidth: 400,
    width: "30%",
    minHeight: 500,
    backgroundColor: "white",
    margin: 10,
    position: "relative",
    cursor: "pointer",
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    marginBottom: 20,
    marginLeft: 30,
  },
  priceTag: {
    position: "absolute",
    top: 0,
    right: 0,
    height: 40,
    minWidth: 40,
    color: "white",
    backgroundColor: "#FF2260",
    zIndex: 3,
    fontSize: 18,
    fontWeight: "bolder",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTopRightRadius: 15,
    borderBottomLeftRadius: 15,
    padding: 5,
  },
  photo: {
    height: 250,
    objectFit: "cover",
    borderRadius: "15px",
    boxShadow: "0px 5px 5px 0px rgba(0,0,0,0.2)",
    zIndex: 2,
  },
  details: {
    borderRadius: " 0 0  15px 15px",
    boxShadow: "0px 5px 5px 0px rgba(0,0,0,0.2)",
    zIndex: 1,
    padding: 20,
    marginTop: -20,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  activity_details: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "80%",
    marginTop: "auto",
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  text: {
    color: "#9B9B9B",
    margin: 5,
    fontSize: 14,
  },
  more: {
    color: "#261170",
    margin: 10,
    fontSize: 16,
    fontWeight: "bold",
  },
  title: {
    color: "#FF2260",
    margin: 0,
    fontSize: 22,
    fontWeight: "bold",
    fontFamily: "helvetica",
    marginTop: 35,
  },
  subtitle: {
    color: "#9B9B9B",
    fontStyle: "italic",
    fontSize: 16,
    marginTop: 0,
  },
  titleContainer: {
    position: "absolute",
    bottom: "22%",
    width: "80%",
    backgroundColor: "#FF2260",
    padding: 10,
    zIndex: 2,
    borderRadius: "0 50px 50px 0",
  },
  user: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#261170",
    minWidth: "70%",
    color: "#fff",
    fontSize: 20,
    margin: 0,
    position: "absolute",
    top: 230,
    left: 5,
    borderRadius: 20,
    height: 40,
    zIndex: 3,
  },
  avatar: {
    width: 60,
    height: 60,
    marginRight: 10,
  },
  separator: {
    width: 50,
    height: 1,
    margin: 0,
    backgroundColor: "#fff",
    border: "none",
  },
  button: {
    width: "100%",
    textTransform: "none",
    borderRadius: 20,
    marginTop: 10,
  },
});
export default ActivityPreview;
