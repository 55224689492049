import React from "react";
import {
  makeStyles,
  TableRow,
  TableCell,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    color: "#9B9B9B",
    border: "none",
  },
  tableRow: {
    border: "1px solid #9B9B9B",
    borderRadius: 5,
  },
}));

export const CustomTableCell = (props) => {
  const classes = useStyles();
  return (
    <TableCell classes={{ root: classes.tableCell }}>
      {props.children}
    </TableCell>
  );
};
export const CustomTableRow = (props) => {
    const classes = useStyles();
    return (
      <TableRow classes={{ root: classes.tableRow }}>
        {props.children}
      </TableRow>
    );
  };