import React from "react";
import { Input, makeStyles, NativeSelect, Select } from "@material-ui/core";

const ProSelect = ({
  label = "",
  labelStyles = {},
  placeholder = "",
  placeholderStyles = {},
  inputStyles = {  },
  value,
  onChange = () => {},
  disableUnderline = true,
  ...props
}) => {
  const classes = useStyles();
  return (
    <>
      <label className={classes.label} style={labelStyles}>
        {label}
      </label>
      <NativeSelect
        classes={{
          root: Object.assign(classes.input, inputStyles),
          select: Object.assign(classes.input, inputStyles),
          nativeInput: Object.assign(classes.input, inputStyles),
        }}
        style={{...inputStyles, minWidth : "50%"}}
        disableUnderline={disableUnderline}
        {...props}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      >
        {props.children}
      </NativeSelect>
    </>
  );
};
const useStyles = makeStyles((theme) => ({
  input: {
    minWidth: "50%",
    backgroundColor: "#FFF",
    borderWidth: "1px",
    borderColor: "#979797",
    borderStyle: "solid",
    borderRadius: 15,
    padding: "5px 10px",
    margin: "10px 0",
    boxShadow: "0 2px 5px rgba(0,0,0,0.5)",
    height: 30,
    lineHeight: "30px",
  },

  label: {
    color: "#979797",
    marginTop: 5,
  },
  placeholder: {
    color: "#979797",
  },
}));
export default ProSelect;
