import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Hidden, Drawer } from "@material-ui/core";
import ActivityPreview from "./HomeComponents/ActivityPreview";
import Filters from "./HomeComponents/Filters";
import MenuIcon from "@material-ui/icons/Menu";
import Header from "../Header";
import RequestAPI from "../../Links";
import { useSnackbar } from "notistack";
import moment from "moment";
import Footer from "../Footer";
import { FormattedMessage } from "react-intl";

const Home = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [events, setEvents] = useState([]);
  const [query, setQuery] = useState("");
  const [date, setDate] = useState(new Date());
  const [isDateEnabled, setIsDateEnabled] = useState(false);
  const [filters, setFilters] = useState({
    category: [],
    type: [],
    public: [],
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleFilters = (filter, value) => {
    if (filters[filter].includes(value)) {
      let tmp = filters;
      tmp[filter] = tmp[filter].filter((e) => e !== value);
      setFilters((prev) => ({ ...prev, ...tmp }));
    } else {
      let tmp = filters;
      tmp[filter].push(value);
      setFilters((prev) => ({ ...prev, ...tmp }));
    }
  };

  const withFilters = (event) => {
    if (filters.category.length > 0) {
      if (!filters.category.includes(event.activity.category)) return false;
    }
    if (filters.type.length > 0) {
      if (!filters.type.includes(event.activity.type)) return false;
    }
    if (filters.public.length > 0) {
      if (!filters.public.includes(event.activity.public)) return false;
    }

    if (isDateEnabled) {
      return moment(event.startDate).isSame(moment(date), "day");
    } else {
      return moment(event.startDate).diff(moment(), "day", true) > 0;
    }
  };

  useEffect(() => {
    if (localStorage.getItem("cart")) {
      enqueueSnackbar(<FormattedMessage id="reservationConfirmed" />, {
        variant: "success",
      });
      localStorage.removeItem("cart");
    }
  }, []);

  useEffect(() => {
    if (query.length > 0) {
      const data = encodeURIComponent(query);
      RequestAPI("GET", "event?search=" + data).then((res) => {
        setEvents(res.data.events);
      });
    } else {
      RequestAPI("GET", "event").then((res) => {
        setEvents(res.data.events);
      });
    }
  }, [query]);

  useEffect(() => {
    localStorage.setItem(
      "activities",
      JSON.stringify(events.filter((event) => withFilters(event)))
    );
  }, [events, filters]);
  return (
    <div className={classes.container}>
      <Header
        color="#9B9B9B"
        handleSearch={(e) => setQuery(e.target.value)}
        searchValue={query}
      />
      <Grid container>
        <Hidden smDown>
          <Grid md={2}>
            <Filters
              value={filters}
              onClick={handleFilters}
              date={date}
              setDate={(newDate) => {
                setDate(newDate);
              }}
              isDateEnabled={isDateEnabled}
              setIsDateEnabled={() => setIsDateEnabled((prev) => !prev)}
            />
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid xs={12} style={{ marginTop: 20, marginBottom: -10 }}>
            <MenuIcon
              color="secondary"
              onClick={() => {
                setOpen(true);
              }}
              className={classes.menuIcon}
            ></MenuIcon>
            <Drawer
              variant="temporary"
              open={open}
              onClose={() => {
                setOpen(false);
              }}
              classes={{
                root: { minWidth: 200 },
              }}
            >
              <Filters
                color="#000"
                value={filters}
                onClick={handleFilters}
                date={date}
                setDate={(newDate) => {
                  setDate(newDate);
                }}
                isDateEnabled={isDateEnabled}
                setIsDateEnabled={() => setIsDateEnabled((prev) => !prev)}
              />
            </Drawer>
          </Grid>
        </Hidden>

        <Grid xs={12} md={10}>
          <div className={classes.activities}>
            {events
              .filter((event) => withFilters(event))
              .map((event) => {
                return <ActivityPreview activity={event} />;
              })}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
    backgroundColor: "#FFF",
    padding: 10,
  },
  activities: {
    display: "flex",
    flexWrap: "wrap",
    paddingTop: 25,
    justifyContent: "stretch",
    alignItems: "stretch",
  },
  headerFlex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  menuIcon: {
    padding: 10,
    fontSize: 30,
  },
}));
export default Home;
