import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { Icon, Collapse, FormControlLabel, Switch } from "@material-ui/core";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { FormattedMessage } from "react-intl";

const Filters = ({
  color = "#9B9B9B",
  value = {},
  onClick = () => {},
  isDateEnabled = false,
  setIsDateEnabled = () => {},
  date = new Date(),
  setDate = (newDate) => {},
}) => {
  const classes = useStyles();

  const handleClick = (property) => {
    setIsOpen((prev) => ({ ...prev, [property]: !isOpen[property] }));
  };

  const [isOpen, setIsOpen] = useState({
    type: true,
    category: true,
    public: true,
  });

  return (
    <div className={classes.container} style={{ color: color }}>
      <h2 className={classes.title}>
        <FormattedMessage id="filterBy" />
      </h2>
      <FormControlLabel
        control={
          <Switch
            color="secondary"
            checked={isDateEnabled}
            onChange={setIsDateEnabled}
            name="datefilter"
          />
        }
        label={<FormattedMessage id="byDate" />}
        labelPlacement="start"
      />
      <DatePicker
        selected={date}
        onChange={setDate}
        inline
        className={classes.datePicker}
        calendarStartDay={1}
        minDate={new Date()}
      />

      <hr className={classes.separator} style={{ marginBottom: 20 }} />

      <div
        className={classes.subtitle}
        onClick={() => {
          handleClick("type");
        }}
      >
        <p>
          <FormattedMessage id="activityType" />s
        </p>
        <Icon>{isOpen.type ? <RemoveIcon /> : <AddIcon />}</Icon>
      </div>

      <Collapse in={isOpen.type}>
        <p
          className={classes.filter}
          onClick={() => {
            onClick("type", "coaching");
          }}
          style={
            value.type.includes("coaching")
              ? {
                  backgroundColor: "rgba(255, 34, 96, 1)",
                  color: "#FFF",
                  margin: "5px 0",
                }
              : {}
          }
        >
          <FormattedMessage id="coaching" />
        </p>
        <p
          className={classes.filter}
          onClick={() => {
            onClick("type", "cours");
          }}
          style={
            value.type.includes("cours")
              ? {
                  backgroundColor: "rgba(255, 34, 96, 1)",
                  color: "#FFF",
                  margin: "5px 0",
                }
              : {}
          }
        >
          <FormattedMessage id="cours" />
        </p>
        <p
          className={classes.filter}
          onClick={() => {
            onClick("type", "atelier");
          }}
          style={
            value.type.includes("atelier")
              ? {
                  backgroundColor: "rgba(255, 34, 96, 1)",
                  color: "#FFF",
                  margin: "5px 0",
                }
              : {}
          }
        >
          <FormattedMessage id="workshop" />
        </p>
      </Collapse>
      <hr className={classes.separator} />
      <div
        className={classes.subtitle}
        onClick={() => {
          handleClick("category");
        }}
      >
        <p>
          <FormattedMessage id="category" />
        </p>
        <Icon>{isOpen.category ? <RemoveIcon /> : <AddIcon />}</Icon>
      </div>
      <Collapse in={isOpen.category}>
        <p
          className={classes.filter}
          onClick={() => {
            onClick("category", "yoga");
          }}
          style={
            value.category.includes("yoga")
              ? {
                  backgroundColor: "rgba(255, 34, 96, 1)",
                  color: "#FFF",
                  margin: "5px 0",
                }
              : {}
          }
        >
          Yoga
        </p>
        <p
          className={classes.filter}
          onClick={() => {
            onClick("category", "langues");
          }}
          style={
            value.category.includes("langues")
              ? {
                  backgroundColor: "rgba(255, 34, 96, 1)",
                  color: "#FFF",
                  margin: "5px 0",
                }
              : {}
          }
        >
          <FormattedMessage id="languages" />
        </p>
        <p
          className={classes.filter}
          onClick={() => {
            onClick("category", "lifestyle");
          }}
          style={
            value.category.includes("lifestyle")
              ? {
                  backgroundColor: "rgba(255, 34, 96, 1)",
                  color: "#FFF",
                  margin: "5px 0",
                }
              : {}
          }
        >
          <FormattedMessage id="lifeStyle" />
        </p>
        <p
          className={classes.filter}
          onClick={() => {
            onClick("category", "developpement");
          }}
          style={
            value.category.includes("developpement")
              ? {
                  backgroundColor: "rgba(255, 34, 96, 1)",
                  color: "#FFF",
                  margin: "5px 0",
                }
              : {}
          }
        >
          <FormattedMessage id="persoDev" />
        </p>
        <p
          className={classes.filter}
          onClick={() => {
            onClick("category", "sport");
          }}
          style={
            value.category.includes("sport")
              ? {
                  backgroundColor: "rgba(255, 34, 96, 1)",
                  color: "#FFF",
                  margin: "5px 0",
                }
              : {}
          }
        >
          <FormattedMessage id="sport" />
        </p>
        <p
          className={classes.filter}
          onClick={() => {
            onClick("category", "nutrition");
          }}
          style={
            value.category.includes("nutrition")
              ? {
                  backgroundColor: "rgba(255, 34, 96, 1)",
                  color: "#FFF",
                  margin: "5px 0",
                }
              : {}
          }
        >
          <FormattedMessage id="nutrition" />
        </p>
        <p
          className={classes.filter}
          onClick={() => {
            onClick("category", "bienetre");
          }}
          style={
            value.category.includes("bienetre")
              ? {
                  backgroundColor: "rgba(255, 34, 96, 1)",
                  color: "#FFF",
                  margin: "5px 0",
                }
              : {}
          }
        >
          <FormattedMessage id="wellBeing" />
        </p>
        <p
          className={classes.filter}
          onClick={() => {
            onClick("category", "creatif");
          }}
          style={
            value.category.includes("creatif")
              ? {
                  backgroundColor: "rgba(255, 34, 96, 1)",
                  color: "#FFF",
                  margin: "5px 0",
                }
              : {}
          }
        >
          <FormattedMessage id="creativeHobbies" />
        </p>
        <p
          className={classes.filter}
          onClick={() => {
            onClick("category", "danse");
          }}
          style={
            value.category.includes("danse")
              ? {
                  backgroundColor: "rgba(255, 34, 96, 1)",
                  color: "#FFF",
                  margin: "5px 0",
                }
              : {}
          }
        >
          <FormattedMessage id="dance" />
        </p>
        <p
          className={classes.filter}
          onClick={() => {
            onClick("category", "culture");
          }}
          style={
            value.category.includes("culture")
              ? {
                  backgroundColor: "rgba(255, 34, 96, 1)",
                  color: "#FFF",
                  margin: "5px 0",
                }
              : {}
          }
        >
          <FormattedMessage id="culture" />
        </p>
        <p
          className={classes.filter}
          onClick={() => {
            onClick("category", "musique");
          }}
          style={
            value.category.includes("musique")
              ? {
                  backgroundColor: "rgba(255, 34, 96, 1)",
                  color: "#FFF",
                  margin: "5px 0",
                }
              : {}
          }
        >
          <FormattedMessage id="music" />
        </p>
        <p
          className={classes.filter}
          onClick={() => {
            onClick("category", "nature");
          }}
          style={
            value.category.includes("nature")
              ? {
                  backgroundColor: "rgba(255, 34, 96, 1)",
                  color: "#FFF",
                  margin: "5px 0",
                }
              : {}
          }
        >
          <FormattedMessage id="natureAndPlants" />
        </p>
        <p
          className={classes.filter}
          onClick={() => {
            onClick("category", "informatique");
          }}
          style={
            value.category.includes("informatique")
              ? {
                  backgroundColor: "rgba(255, 34, 96, 1)",
                  color: "#FFF",
                  margin: "5px 0",
                }
              : {}
          }
        >
          <FormattedMessage id="informatic" />
        </p>
        <p
          className={classes.filter}
          onClick={() => {
            onClick("category", "scolaire");
          }}
          style={
            value.category.includes("scolaire")
              ? {
                  backgroundColor: "rgba(255, 34, 96, 1)",
                  color: "#FFF",
                  margin: "5px 0",
                }
              : {}
          }
        >
          <FormattedMessage id="schoolTutoring" />
        </p>
      </Collapse>
      <hr className={classes.separator} />
      <div
        className={classes.subtitle}
        onClick={() => {
          handleClick("public");
        }}
      >
        <p>
          <FormattedMessage id="public" />
        </p>
        <Icon>{isOpen.public ? <RemoveIcon /> : <AddIcon />}</Icon>
      </div>
      <Collapse in={isOpen.public}>
        <p
          className={classes.filter}
          onClick={() => {
            onClick("public", "adulte");
          }}
          style={
            value.public.includes("adulte")
              ? {
                  backgroundColor: "rgba(255, 34, 96, 1)",
                  color: "#FFF",
                  margin: "5px 0",
                }
              : {}
          }
        >
          <FormattedMessage id="adults" />
        </p>
        <p
          className={classes.filter}
          onClick={() => {
            onClick("public", "senior");
          }}
          style={
            value.public.includes("senior")
              ? {
                  backgroundColor: "rgba(255, 34, 96, 1)",
                  color: "#FFF",
                  margin: "5px 0",
                }
              : {}
          }
        >
          <FormattedMessage id="seniors" />
        </p>
        <p
          className={classes.filter}
          onClick={() => {
            onClick("public", "junior");
          }}
          style={
            value.public.includes("junior")
              ? {
                  backgroundColor: "rgba(255, 34, 96, 1)",
                  color: "#FFF",
                  margin: "5px 0",
                }
              : {}
          }
        >
          <FormattedMessage id="youngPublic" />
        </p>
        <p
          className={classes.filter}
          onClick={() => {
            onClick("public", "couple");
          }}
          style={
            value.public.includes("couple")
              ? {
                  backgroundColor: "rgba(255, 34, 96, 1)",
                  color: "#FFF",
                  margin: "5px 0",
                }
              : {}
          }
        >
          <FormattedMessage id="couplePriceForTwo" />
        </p>
        <p
          className={classes.filter}
          onClick={() => {
            onClick("public", "enfant");
          }}
          style={
            value.public.includes("enfant")
              ? {
                  backgroundColor: "rgba(255, 34, 96, 1)",
                  color: "#FFF",
                  margin: "5px 0",
                }
              : {}
          }
        >
          <FormattedMessage id="parentsAndChild" />
        </p>
      </Collapse>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    fontSize: 14,
    padding: 10,
  },
  title: {
    fontSize: 16,
  },
  subtitle: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 20,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#fcfcfc",
    },
  },
  separator: {
    width: "100%",
    height: 1,
    margin: 0,
    backgroundColor: "#9B9B9B",
    border: "none",
  },
  filters: {
    transition: "height 600ms ease",
    overflowY: "hidden",
  },
  opened: {
    height: "100%",
  },
  closed: {
    height: 0,
  },
  filter: {
    margin: 0,
    padding: "10px 15px",
    borderRadius: 20,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(255, 34, 96, 0.5)",
      color: "#FFF",
    },
  },
  datePicker: {
    width: "100%",
  },
});
export default Filters;
