import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";
import {
  Grid,
  StylesProvider,
  InputAdornment,
  FilledInput,
  FormControlLabel,
  Checkbox,
  Button,
  Hidden,
} from "@material-ui/core";
import image from "../../img/login.png";
import logo from "../../img/logo.svg";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import HttpsOutlinedIcon from "@material-ui/icons/HttpsOutlined";
import { Link, useHistory } from "react-router-dom";
import {
  Firebase,
  signInWithFacebook,
  signInWithGoogle,
} from "../../Firebase/config";

const Login = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    Firebase.auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        console.log({ success: true });
      })
      .catch((err) => {
        setError(err.message);
      });
  };
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Grid container>
        <Hidden xsDown>
          <Grid sm={4} md={6}>
            <div className={StylesProvider.aside}>
              <img src={image} alt="yoga" className={classes.image} />
              <img src={logo} alt="logo" className={classes.logo} />
            </div>
          </Grid>
        </Hidden>
        <Grid xs={12} sm={8} md={6}>
          <form className={classes.form} onSubmit={handleSubmit}>
            <p>
              <FormattedMessage id="connectToYourAccount" />
            </p>
            <h1 className={classes.title}>
              <FormattedMessage id="login" />
            </h1>
            <label className={classes.label} for="email">
              Email
            </label>
            <FilledInput
              classes={{
                root: classes.input,
              }}
              name="email"
              variant="filled"
              disableUnderline
              required
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              startAdornment={
                <InputAdornment position="start">
                  <MailOutlineOutlinedIcon classes={{ root: classes.icon }} />
                </InputAdornment>
              }
            />
            <label className={classes.label} for="email">
              Mot de passe
            </label>
            <FilledInput
              classes={{
                root: classes.input,
              }}
              name="password"
              type="password"
              required
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              variant="filled"
              disableUnderline
              startAdornment={
                <InputAdornment position="start">
                  <HttpsOutlinedIcon classes={{ root: classes.icon }} />
                </InputAdornment>
              }
            />
            <FormControlLabel
              className={classes.checkbox}
              value="remember"
              control={<Checkbox color="primary" />}
              label="Se souvenir de moi"
              labelPlacement="end"
            />
            <p className={classes.error}>{error}</p>
            <div className={classes.flexbox}>
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                classes={{ root: classes.button }}
              >
                <FormattedMessage id="continue" />
              </Button>

              <Link>
                <FormattedMessage id="forgotPassword" />
              </Link>
            </div>
            <Button
              color="primary"
              variant="contained"
              classes={{ root: classes.buttonSocial }}
              onClick={() => {
                signInWithFacebook();
              }}
            >
              <FormattedMessage id="connectWithFB" />
            </Button>
            <Button
              color="primary"
              variant="contained"
              classes={{ root: classes.buttonSocial }}
              onClick={() => {
                signInWithGoogle();
              }}
            >
              <FormattedMessage id="connectWithGoogle" />
            </Button>
            {/* <button
              onClick={() => {
                
              }}
            >
              Sign In with Google
            </button> */}
            <p>
              <FormattedMessage id="noAccount" />
            </p>
            <Link to="/register">S'inscrire</Link>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    padding: "20px 100px",
    justifyContent: "center",
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: 10,
    },
  },
  title: {
    marginTop: 50,
    marginBottom: 70,
  },
  aside: {
    position: "relative",
  },
  image: { objectFit: "cover", height: "100vh", width: "100%" },
  logo: {
    width: 100,
    position: "absolute",
    top: 30,
    left: 30,
  },
  icon: {
    color: "#3E7EF8",
  },
  label: {
    fontSize: 16,
    margin: "20px 10px",
  },
  input: {
    borderRadius: 4,
    backgroundColor: "#F7F7F7",
    "& input": {
      padding: "12px 12px 12px 0",
    },
  },
  button: {
    borderRadius: 50,
    textTransform: "none",
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: 16,
    width: 180,
    marginRight: 10,
  },
  checkbox: {
    marginTop: 20,
    marginBottom: 20,
  },
  flexbox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  buttonSocial: {
    margin: 10,
  },
  error: {
    color: "red",
  },
}));
export default Login;
