import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";

const config = {
  apiKey: "AIzaSyB5W0ujHe1PNlfSq_OQ0JN-baicVSNMTr8 ",
  authDomain: "codjy-6cc40.firebaseapp.com",
  databaseURL: "https://codjy-6cc40.firebaseio.com",
  projectId: "codjy-6cc40",
  storageBucket: "codjy-6cc40.appspot.com",
  messagingSenderId: "337769663198",
  appId: "1:337769663198:web:993441e83c3840c54f504f",
};

export const Firebase = firebase.initializeApp(config);

const googleAuth = new firebase.auth.GoogleAuthProvider();
googleAuth.setCustomParameters({ promt: "select_account" });

const storageRef = Firebase.storage().ref();

export const uploadFile = async (name, file) => {
  var imagesRef = storageRef.child("images/" + name);
  const res = await imagesRef.put(file);
  return res;
};
export const getImageUrl = (image) => {
  const fileURLEncoded = encodeURIComponent(image);
  console.error(fileURLEncoded)
  const url = `https://firebasestorage.googleapis.com/v0/b/${config.storageBucket}/o/images%2F${fileURLEncoded}?alt=media`;
  return url;
};

export const signInWithGoogle = () =>
  Firebase.auth().signInWithPopup(googleAuth);

const facebookAuth = new firebase.auth.FacebookAuthProvider();
googleAuth.setCustomParameters({ display: "popup" });

export const signInWithFacebook = () =>
  Firebase.auth().signInWithPopup(facebookAuth);

export const signOut = () => {
  Firebase.auth().signOut();
};

export const signIn = (email, password) => {
  Firebase.auth()
    .signInWithEmailAndPassword(email, password)
    .then(() => {
      return { success: true };
    })
    .catch((err) => {
      return { success: false, error: err };
    });
};

export const register = (email, password, name) => {
  Firebase.auth()
    .createUserWithEmailAndPassword(email, password)
    .then((user) => {
      user.updateProfile({
        displayName: name,
      });
    })
    .catch((err) => {
      return { success: false, error: err };
    });
};
