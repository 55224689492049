import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import Header from "../Header";
import RequestAPI from "../../Links";

import { FirebaseContext } from "../../Firebase/FirebaseContext";

const Meeting = () => {
  const classes = useStyles();
  const { password, id } = useParams();
  const jitsiContainerId = "jitsi-container-id";
  const [jitsi, setJitsi] = useState({});
  const history = useHistory();
  const { user } = useContext(FirebaseContext);

  const loadJitsiScript = () => {
    let resolveLoadJitsiScriptPromise = null;

    const loadJitsiScriptPromise = new Promise((resolve) => {
      resolveLoadJitsiScriptPromise = resolve;
    });

    const script = document.createElement("script");
    script.src = "https://meet.jit.si/external_api.js";
    script.async = true;
    script.onload = () => resolveLoadJitsiScriptPromise(true);
    document.body.appendChild(script);

    return loadJitsiScriptPromise;
  };

  const initialiseJitsi = async () => {
    if (!window.JitsiMeetExternalAPI) {
      await loadJitsiScript();
    }

    const _jitsi = new window.JitsiMeetExternalAPI("meet.jit.si", {
      parentNode: document.getElementById(jitsiContainerId),
      roomName: "codjy-live-" + id,

      userInfo: {
        email: user?.email,
        displayName: user?.userName || "",
      },
      configOverwrite: {
        requireDisplayName: true,
      },
      interfaceConfigOverwrite: {
        SHOW_JITSI_WATERMARK: false,
        SHOW_WATERMARK_FOR_GUESTS: false,
      },
    });

    setJitsi(_jitsi);
  };

  React.useEffect(() => {
    RequestAPI("GET", `event/${id}`)
      .then((res) => {
        console.log(res.data);
        if (res.data.event.activity.password == password) {
          initialiseJitsi();
        } else {
         // history.replace("/home");
        }
      })
      .catch(() => {
        //history.replace("/home");
      });

    return () => jitsi?.dispose?.();
  }, []);

  return (
    <div className={classes.wrapper}>
      <Header color="#9B9B9B" />
      <div className={classes.container} id={jitsiContainerId}></div>
      <p>{jitsi?.password}</p>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: "100vh",
    padding: 10,
  },
  container: {
    width: "80%",
    margin: "50px auto",
    color: "#9B9B9B",
    height: "90vh",
  },
}));
export default Meeting;
